import { Box, Grid, MenuItem, Typography } from "@mui/material";
import { ErrorMessage, Field, useFormikContext } from "formik";
import { Select, TextField } from "formik-mui";
import { FC, useCallback } from "react";
import { apiClient } from "../../../../api/apiClient";
import { useClients, useInit, useProfessionals } from "../../../../hooks";
import { SubDocsFormValues } from "./subDocsFormSchema";

export const FinancialAdvisor: FC = () => {
  const { professional,advisory, professionalsList, setProfessionalsList} = useProfessionals();
  const { setFieldValue, setTouched, touched } = useFormikContext<SubDocsFormValues>();
  const { addressStates } = useClients();

  const init = useCallback(async () => {
    if (professionalsList == null || professionalsList.length === 0) {
      const professionalsResp  = await apiClient.get("/professionals/advisories/:p0/professionals", {routeParams: [professional!.advisory_id] });
      setProfessionalsList(professionalsResp.professionalsList);
    }
  }, [professional, professionalsList, setProfessionalsList])

  useInit(init);

  return (
    <Box sx={{ py: 2,}}>
      <ErrorMessage  name="error"/>
      <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%', pb: 2}}>
        {advisory && <Field
          sx={{width: "46vw", minWidth: 550, maxWidth: 700, mr: 2}}
          component={Select}
          fullWidth
          label="Firm"
          disabled
          renderValue={(firmId: number) => {
            const currentFirm = advisory?.id?.toString() === firmId.toString() ? advisory : null
            return <Typography>{currentFirm ? currentFirm.name : ""}</Typography>
          }}
          name="firm"
        >
          {[advisory].map((firm) => <MenuItem key={firm.id} value={firm.id.toString()}><option value={firm.id.toString()}>{firm.name}</option></MenuItem>)}
        </Field>}
        <Field sx={{width: "46vw", minWidth: 550, maxWidth: 700}}
          component={Select}
          fullWidth  
          label="Advisor Name*"
          name="advisorId"
          onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
          onChange={(event: any) => {
            const value = +event.target.value;
            const currentAdvisor = professionalsList.find(advisor => advisor.id === value);
            setFieldValue("advisorId", value);
            if (currentAdvisor) {
              setFieldValue("advisorEmail", currentAdvisor.email, false)
              setFieldValue("advisorPhoneNumber", currentAdvisor.phone, false)
              // Set the fields as touched in the next task so validation will run after all values have updated
              // RESEARCH: See if the formik API has a built in way of accomplishing this. (SetFieldValue might be awaitable?!?)
              //TODO: Phase out the setTimeout and use async-await instead, now that typing of for `setFieldValue` is up to date
              setTimeout(() => {
                setTouched({
                  ...touched,
                  advisorEmail: true,
                  advisorPhoneNumber: true,
                });
              })
            }
          }}
        >
          {professionalsList && professionalsList
            .slice().sort((a, b) => `${a.first_name} ${a.last_name}`.localeCompare(`${b.first_name} ${b.last_name}`))
            .map((advisor) => <MenuItem key={advisor.id} value={advisor.id}>{`${advisor.first_name} ${advisor.last_name}`}</MenuItem>)}
        </Field>
      </Box>
      <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
        <Field name="advisoryAddress.street1" required label="Street Address 1" component={TextField} sx={{width: "46vw", minWidth: 550, maxWidth: 700, mr: 2}}/>
        <Field name="advisoryAddress.street2" label="Street Address 2" component={TextField} sx={{width: "46vw", minWidth: 550, maxWidth: 700}}/>
      </Box>
      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Field name="advisoryAddress.city" required label="City" component={TextField} sx={{width: 'calc(42% - 8px)', mr: 2}} />
        <Field 
          name="advisoryAddress.stateId" 
          label="State" 
          component={Select} 
          formControl={{required: true}}
          sx={{ width: '200px',}}
          onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
          >
            {addressStates != null && addressStates.map(state => (
              <MenuItem key={state.id} value={state.id}>{state.name}</MenuItem>
            ))}
        </Field>
        <Field name="advisoryAddress.zip" label="ZIP Code" component={TextField} sx={{ml: 2,width: 'calc(42% - 8px)'}}/>
      </Box>
      <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field name='advisorEmail' required label="Email" component={TextField} fullWidth/>
          </Grid>
          <Grid item xs={6}>
            <Field label="Phone Number" required component={TextField} name='advisorPhoneNumber' fullWidth/>
          </Grid>
        </Grid>
    </Box>
  )
}