import { Alert, AutocompleteRenderInputParams, Box, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { Autocomplete } from "formik-mui";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { NumberFormatValues } from "react-number-format";
import { apiClient } from "../../../../api/apiClient";
import { InterestedClient, InvestedClient, Investor, RegDInterestedClient } from "../../../../api/payloads";
import { Account, Custodian } from "../../../../api/payloads/customer.payload";
import CurrencyFieldText from "../../../../components/FormFields/CurrencyField";
import { Client } from "../../../../context/ClientsContext";
import { useUI } from "../../../../hooks";
import { toCurrency } from "../../../../utility/number.util";
import { InvestorFormValues } from "./add-investor-model";
import { isAccountEligible } from "./account-eligibility";

type Props = {
  accounts: Account[],
  setAccounts: (accounts: Account[]) => void,
  clients: Client[],
  custodiansList: Custodian[],
  investedList: Investor[] | InvestedClient[],
  interestedList: InterestedClient[] | RegDInterestedClient[], 
  isClientPreSelected: boolean;
}

export const AddInvestorFormFields = ({ accounts, setAccounts, clients, interestedList, investedList, isClientPreSelected, custodiansList  }: Props) => {
  const { values, setFieldValue, errors, setFieldError, setFieldTouched, touched, validateForm, getFieldProps } = useFormikContext<InvestorFormValues>();
  const { account } = values;
  const { setError } = useUI();
  const [isAccountsLoading, setIsAccountsLoading] = useState<boolean>(false);
  const accountInputRef = useRef<HTMLDivElement | null>(null);
  const autoCompleteInputWidth = accountInputRef.current?.offsetWidth ?? 'unset';
  const [hasPriorInvestment, setHasPriorInvestment] = useState<boolean>(false);
  const investmentCompanyInfo = values.investmentCompany;

  const autoCompleteComponentProps = useMemo(() => ({
    popper: {style: { width: 'auto', minWidth: autoCompleteInputWidth }}
  }), [autoCompleteInputWidth])

  useEffect(() => {
    const findInterestedEntry = () => {
      return (interestedList as (InterestedClient | RegDInterestedClient)[]).find(
        (investor) => investor.account_id === account?.id
      );
    };
    const findInvestedEntry = () => {
      return (investedList as (Investor | InvestedClient)[]).find(
        (investor) => investor.account_id === account?.id
      );
    };

    const interestedEntry = findInterestedEntry();
    const investedEntry = findInvestedEntry();
  
    setFieldValue('existingEntryListEntry', interestedEntry);
  
    const hasPriorInvestment = !!investedEntry;
    setHasPriorInvestment(hasPriorInvestment);
  
    setFieldValue('isSubsequentInvestment', !!(interestedEntry || investedEntry))
      .then(() => validateForm());
  
  }, [account, interestedList, investedList, setFieldValue, validateForm]);

  const handleClientChange = useCallback(async (_event: unknown, value: Client) => {
    await Promise.all([
      setFieldValue('client', value),
      setFieldValue('account', null),
    ])

    setFieldTouched('client', true)
    setAccounts([])

    if (!value) {
      return;
    }

    try {
      setIsAccountsLoading(true);
      const resp = await apiClient.get("/customers/:p0", { routeParams: [value?.id!] });
      setAccounts(resp.accounts)
    } catch (error) {
      setError("Error fetching client data")
      setAccounts([]);
    } finally {
      setIsAccountsLoading(false);
    }
  }, [setAccounts, setFieldValue, setFieldTouched, setError]);

  const handleAccountChange = useCallback(async (_event: unknown, value: Account) => {
    await setFieldValue('account', value);
    setFieldTouched('account', true);
  }, [setFieldValue, setFieldTouched]);

  return (
    <>
      <Grid sx={{ mb: 1, pt: 3 }} container spacing={2}>
        <Grid item xs={6}>
          <Field
            name="client"
            component={Autocomplete}
            autoHighlight
            openOnFocus
            options={clients}
            getOptionLabel={(client: Client,) => client.name}
            onChange={handleClientChange}
            componentsProps={autoCompleteComponentProps}
            disabled={isClientPreSelected}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField
                {...params}
                name="client"
                error={touched.client && !!errors.client}
                helperText={touched.client && errors.client ? errors.client : " "}
                label="Client"
                variant="outlined"
              />
            )}
            renderOption={(props: AutocompleteRenderInputParams, client: Client) => {
              return (
                <Typography {...props} key={client.id}>{client.name}</Typography>
              );
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="account"
            component={Autocomplete}
            autoHighlight
            openOnFocus
            loading={isAccountsLoading}
            options={accounts}
            isOptionEqualToValue={(option: Account, value: Account) => accounts.length > 0 && value ? option?.id === value?.id : true}
            getOptionLabel={(account: Account) => account.account_name}
            onChange={handleAccountChange}
            componentsProps={autoCompleteComponentProps}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField
                ref={accountInputRef}
                {...params}
                name="account"
                error={touched.account && !!errors.account}
                helperText={touched.account && errors.account ? errors.account : " "}
                label={'Account'}
                variant="outlined"
              />
            )}
            renderOption={(props: AutocompleteRenderInputParams, account: Account) => {
              const isEligibleAccount = isAccountEligible(account, investmentCompanyInfo, custodiansList);

              if ((!isEligibleAccount)) {
                props.disabled = true
              }
              return <MenuItem {...props} key={account.id}>{account.account_name}</MenuItem>;
            }}
          />
        </Grid>
      </Grid>
      <Box>
        <CurrencyFieldText
          label={values.isBdc ?
            `Subscription Amount (No less than ${values.isSubsequentInvestment ? '$5,000' : '$10,000'})`
            : `Commitment (No less than ${toCurrency(values.investmentCompany.minimum_commitment)})`
          }
          name="investmentAmount"
          disabled={!!errors.account || !!errors.client}
          sx={{ mb: 2, height: 60, width: 'calc(50% - 8px)' }}
          value={values.investmentAmount}
          error={(touched.investmentAmount && !!errors.investmentAmount)}
          helperText={errors.investmentAmount && touched.investmentAmount ? errors.investmentAmount : " "}
          onBlur={(e: React.FocusEvent) => getFieldProps('investmentAmount').onBlur(e)}
          onValueChange={(values: NumberFormatValues) => setFieldValue('investmentAmount', values.floatValue)}
        />
      </Box>
      {hasPriorInvestment && !values.isBdc && !errors.account && (
        <Grid item xs={12}>
          <Alert severity="info" sx={{}}>
            <Typography>
              Account is already an investor. This investment will be merged with the existing one.
            </Typography>
          </Alert>
        </Grid>
      )}
    </>
  );
};
