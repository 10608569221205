import { Box, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { FC, useMemo, useState } from "react";
import { apiClient } from "../../../../api/apiClient";
import { Accreditation, EntityType, PostCustomerEntityReq } from "../../../../api/payloads/customer.payload";
import { CornerCloseButton, LoadingButton } from "../../../../components/Buttons";
import { FormikStepValidationHelper } from "../../../../components/FormikStepValidationHelper/FormikStepValidationHelper";
import { useClientDetails, useInit, useUI } from "../../../../hooks";
import { createDocument } from "../../../../services/document.service";
import { DocumentTypes, GrantorStrings, RevocableStrings } from "../../../../utility/constants";
import { FormikSubmitFn } from "../../../../utility/types";
import EntityForm from "../../NewClientPage/Forms/EntityForm";
import { entityDocumentFormSchema, EntityDocumentFormValues, initialDocumentValues, initialEntityValues } from "../../NewClientPage/Forms/newClientFormSchema";

type AddEntityDialogProps = {
  onClose: (result?: boolean) => void,
  clientId: number,
}

export const AddEntityDialog: FC<AddEntityDialogProps> = ({onClose, clientId}) => {
  const { setError, setSuccess } = useUI();
  const [isLoading, setLoading] = useState(false);
  const {activeClient} = useClientDetails();
  const [accreditationValues, setAccreditationValues ] = useState<Accreditation[]>([])
  const [entityTypeValues, setEntityTypeValues ] = useState<EntityType[]>([])

  const entityAndDocumentInitialValues = useMemo((): EntityDocumentFormValues => ({
    ...initialEntityValues,
    ...initialDocumentValues,
  }), [])

  useInit(async () => {
    const resp = await apiClient.get("/customers/new-client-field-values");
    setAccreditationValues(resp.accreditations);
    setEntityTypeValues(resp.entityTypes);
  })

  const handleEntitySubmit: FormikSubmitFn<EntityDocumentFormValues> = async (values) => {
    if (clientId == null)  {
      return;
    }

    setLoading(true);

    try {
      const { entityOwners, ...rest } = values

      const createEntity: PostCustomerEntityReq = {
        entity: {
          accreditation_id: Number(rest.qualification),
          customer_id: clientId,
          ein: rest.EIN,
          is_grantor: rest.grantor === GrantorStrings.Grantor,
          is_revocable: rest.revocable === RevocableStrings.Revocable,
          name: rest.entityName,
          type: Number(rest.entityType),
          other_info: rest.other_info ? rest.other_info : null
        },
        owners: entityOwners.map(o => ({
          contact_id: Number(o.id),
          percentage: Number(o.percentage)
        })),
      }

      let formationDocumentId: number | undefined;
      let governingDocumentId: number | undefined;
      let trustDocument: number | undefined;

      if (rest.formationDocuments && rest.formationDocuments instanceof File) { 
        formationDocumentId = await createDocument(rest.formationDocuments, {
          customerId: clientId,
          documentTypeId: DocumentTypes.FormationDocuments,
          fileName: rest.formationDocuments.name,
        })
      }

      if (rest.governingDocuments && rest.governingDocuments instanceof File) {
        governingDocumentId = await createDocument(rest.governingDocuments, {
          customerId: clientId,
          documentTypeId: DocumentTypes.GoverningDocuments,
          fileName: rest.governingDocuments.name,
        })
      }

      if (rest.trustDocument && rest.trustDocument instanceof File) {
        trustDocument = await createDocument(rest.trustDocument, {
          customerId: clientId,
          documentTypeId: DocumentTypes.TrustDocuments,
          fileName: rest.trustDocument.name,
        })
      }

      const newEntityResp = await apiClient.post("/customers/entities", {data: createEntity});

      if (formationDocumentId) {
        await apiClient.post('/documents/customers-documents/:p0/entity/:p1', {
          routeParams: [clientId, newEntityResp.entityId],
          data: { documentId: formationDocumentId },
        });
      }

      if (governingDocumentId) {
        await apiClient.post('/documents/customers-documents/:p0/entity/:p1', {
          routeParams: [clientId, newEntityResp.entityId],
          data: { documentId: governingDocumentId },
        });
      }

      if (trustDocument) {
        await apiClient.post('/documents/customers-documents/:p0/entity/:p1', {
          routeParams: [clientId, newEntityResp.entityId],
          data: { documentId: trustDocument },
        });
      }

      setLoading(false);
      setSuccess("Successfully added entity");
      onClose(true);
    } catch (e) {
      setError("Oops, something went wrong. Please try again later")
      setLoading(false)
      onClose(false)
    }
  };
  
  return (
    <>
      <DialogTitle sx={{pt: 5}}>
        <Box display="flex" justifyContent={"space-between"} alignItems="center">
          <Typography variant="h5">Add New Entity</Typography>
        </Box>
        <CornerCloseButton onClick={() => onClose()}></CornerCloseButton>
      </DialogTitle>
        <Formik 
          initialValues={entityAndDocumentInitialValues} 
          validationSchema={entityDocumentFormSchema} 
          onSubmit={handleEntitySubmit}
        >
          {({isValid}) => (
          <Form>
            <FormikStepValidationHelper activeStep={0} />
            <DialogContent>
              <EntityForm accreditationValues={accreditationValues} entityTypeValues={entityTypeValues} newMembers={activeClient?.contacts ?? []} />
            </DialogContent>
            <DialogActions>
              <LoadingButton disabled={!isValid} color='info' type='submit' loading={isLoading}>Submit</LoadingButton>
            </DialogActions>
          </Form>
          )}
        </Formik>
    </>
  )
}
