import { Box, MenuItem } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { Select, TextField } from 'formik-mui';
import { useClients, useSubDocs } from '../../../../../hooks';
import { DocumentFileUploadField } from '../../../../Clients/NewClientPage/Forms/CustomFields/DocumentFileUploadField';

export default function BrokerageInstructionFields() {
  const {addressStates} = useClients();
  const {custodians} = useSubDocs();
  const {setFieldValue} = useFormikContext();
  const handleCustodianSelection = (event: any) => {
    const custodian = custodians.find(custodian => custodian.id === event?.target?.value)
    setFieldValue("brokerageInstructions.custodianId", custodian?.id ?? "")
    setFieldValue("brokerageInstructions.aba", custodian?.distribution_instruction_aba ?? "")
    setFieldValue("brokerageInstructions.bankAccountName", custodian?.name ?? "")
    setFieldValue("brokerageInstructions.accountNumber", custodian?.distribution_instruction_account_number ?? "")
    setFieldValue("brokerageInstructions.financialInstitutionName", custodian?.financial_institution_name ?? "")
    setFieldValue("brokerageInstructions.financialInstitutionAddressId", custodian?.financial_institution_address_id ?? "")
    setFieldValue("brokerageInstructions.financialCity", custodian?.financial_institution_city ?? "")
    setFieldValue("brokerageInstructions.financialStateId", custodian?.financial_institution_state_id ?? "")
  }

  return (
    <Box sx={{mt: 4, mb:2}}>
      <Box>
        <Field name="brokerageInstructions.instructionName" required label="Distribution Instruction Friendly Name" component={TextField} sx={{width: 'calc(50% - 8px)', mr: 2}}/>
        <Field 
          name="brokerageInstructions.custodianId" 
          label="Custodian" 
          component={Select} 
          formControl={{required: true, sx:{width: 'calc(50% - 8px)'}}}
          onChange={handleCustodianSelection}
          onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
          >
            {custodians.filter(custodian => custodian.use_for_brokerage_instruction).map(custodian => (
              <MenuItem key={custodian.id} value={custodian.id}>{custodian.name}</MenuItem>
            ))}
        </Field>
      </Box>
      <Box>
        <Field disabled name="brokerageInstructions.bankAccountName" required label="Name on Bank Account" component={TextField} sx={{width: 'calc(50% - 8px)', mr: 2}}/>
        <Field disabled name="brokerageInstructions.aba" required label="Bank's Routing Number" component={TextField} sx={{width: 'calc(50% - 8px)'}}/>
      </Box>
      <Box>
        <Field disabled name="brokerageInstructions.accountNumber" required label="Bank Account Number" component={TextField} sx={{width: 'calc(50% - 8px)', mr: 2}}/>
      </Box>
      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
      <Field disabled name="brokerageInstructions.financialInstitutionName" required label="Financial Institution Name" sx={{width: 'calc(42% - 8px)', mr: 2}} component={TextField} />
        <Field disabled name="brokerageInstructions.financialCity" required label="City" component={TextField} sx={{width: 'calc(42% - 8px)', mr: 2}} />
        <Field 
          disabled
          name="brokerageInstructions.financialStateId" 
          label="State" 
          component={Select} 
          formControl={{required: true}}
          sx={{ width: '200px',}}
          onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
          >
            {addressStates != null && addressStates.map(state => (
              <MenuItem key={state.id} value={state.id}>{state.name}</MenuItem>
            ))}
        </Field>
      </Box>
        <Box>
          <Field name="brokerageInstructions.ffc" label="Reference (For Further Credit)" component={TextField} sx={{width: 'calc(50% - 8px)', mr: 2}}/>
          <Field name="brokerageInstructions.ffcAccountNumber" label="FFC to Account Number" component={TextField} sx={{width: 'calc(50% - 8px)'}}/>
          <DocumentFileUploadField
          accept=".pdf"
          label="Verification Document"
          name="brokerageInstructions.verificationDocument"
          sx={{width: 'calc(33% - 8px)', mr: 2}}
          />
        </Box>
    </Box>
  );
}