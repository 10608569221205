import { Search } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Button, Checkbox, Chip, FormControl, FormControlLabel, IconButton, InputAdornment, Link, Menu, MenuItem, Paper, Select, SelectChangeEvent, TableContainer, TextField, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { apiClient } from "../../../api/apiClient";
import { InvestorUpdate, RegDInterestedClient } from "../../../api/payloads";
import { DownloadUploadSplitButton } from "../../../components/Buttons";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog/ConfirmationDialog";
import { ColumnDef, DataSourceTable, RowState } from "../../../components/DataSourceTable";
import { ErrorContactEmail } from "../../../components/ErrorContactEmail/ErrorContactEmail";
import CurrencyFieldText from "../../../components/FormFields/CurrencyField";
import { useAuth, useClients, useInvestments, useMaskedParams, useOpportunities, useUI } from "../../../hooks";
import { createDocument } from "../../../services/document.service";
import { DocumentTypes, InvestorStatus } from "../../../utility/constants";
import { formatDate } from "../../../utility/date.util";
import { invalidFileDataTest, largeFileTest } from "../../../utility/file.util";
import { isNullish } from "../../../utility/misc.util";
import { toCurrency } from "../../../utility/number.util";
import { isAxiosError } from "../../../utility/type-guards";
import { FilterOption } from "./InterestedList";
import { InvestorDialog } from "./InvestorDialog";
import useBoolean from "../../../hooks/useBoolean";
import { useLocation, useNavigate } from "react-router-dom";


type FilterOptions = {
  "Document Status": FilterOption[],
  "Monies Status": FilterOption[],
}

enum DocumentStatus {
  NotReceived = 0,
  Completed = 1,
  /** 
   * Important! This does not 1-to-1 map to docs_received value, as that value is still binary.
   * Rather, it would map to a state of `docs_received === 0 && !isNullish(subscription_document_id)`.
   * Purely for presentation layer of Advisor Vision at this point
   */
  Submitted = 2,
}

enum MoniesStatus { 
  NotReceived = 0,
  Received = 1,
}

export const RegDInterestedList: FC = () => {
  const {user} = useAuth()
  const {investmentId} = useMaskedParams();
  const { opportunitiesMap } = useOpportunities();
  const { regDInterestedList, setRegDInterestedList , investors } = useInvestments();
  const minimumCommitment = opportunitiesMap[parseInt(investmentId)]?.minimum_commitment;
  const [searchFilter, setSearchFilter] = useState("");
  const [filteredRows, setFilteredRows] = useState(regDInterestedList);
  const [rowId, setRowId] = useState<number | null>() 
  const [subscriptionAmount, setSubscriptionAmount] = useState<number | null>()
  const {setError, setLoading, setSuccess} = useUI()
  const [selectedCategory, setSelectedCategory] = useState<keyof FilterOptions>('Document Status');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<RegDInterestedClient | null>(null);
  const [confirmationDialogConfig, setConfirmationDialogConfig] = React.useState<{ title: string, message: string } | null>(null);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState<number[]>(filterOptions()[selectedCategory].map((option) => option.value));
  const opportunity = opportunitiesMap[+investmentId];
  const [openAddInvestorDialog, setOpenAddInvestorDialog] = useState(false);
  const {clientsMap} = useClients();
  const { setTrue: setRefreshDataTrue } = useBoolean(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [rowState, setRowState] = useState<{ [key: number]: { isHighlighted: boolean } }>({});

  useEffect(() => {
    if (location.state?.highlightedRowId) {
      setRowState((prevState) => ({
        ...prevState,
        [location.state.highlightedRowId]: { isHighlighted: true },
      }));
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location.state?.highlightedRowId, navigate, location.pathname]);

  const handleRowStateChange = (rowId: string | number, newState: Partial<RowState[number]>) => {
    setRowState((prevState) => ({
      ...prevState,
      [rowId]: {
        ...prevState[+rowId],
        ...newState,
      },
    }));
  };

  useEffect(() => {
    if (regDInterestedList.length === 0) {
      const fetchInterestListData = async () => {
        try {
          const investors = await apiClient.get(`/investors/investment-companies/:p0/investors`, { routeParams: [investmentId] });
          setRegDInterestedList(investors.regDInterestedList);
        } catch (error) {
          console.error("Failed to fetch interest list data:", error);
        }
      };
      fetchInterestListData();
    } 
  }, [investmentId, regDInterestedList, setRegDInterestedList]);

  const handleFilterChange = (checked: boolean, selection: FilterOption ) => {
    if (!checked) {
      setSelectedFilterOptions((prevState: number[]) => (prevState.filter((value) => {
        return value !== selection.value})));
      return;
    } else {
      setSelectedFilterOptions((prevState: number[]) => {
        return ([ ...prevState, selection.value ])});
    }
  };

  const handleOptionChange = (event: SelectChangeEvent<number[]>) => {
    setSelectedFilterOptions(event.target.value as number[]);
  };
  
  const handleCategoryChange = (event: SelectChangeEvent<keyof FilterOptions>) => {
    setSelectedCategory(event.target.value as keyof FilterOptions);
    setSelectedFilterOptions(filterOptions()[event.target.value as keyof FilterOptions].map((option) => option.value));
  }
  
  const handleClickEllipsis = (event: React.MouseEvent<HTMLElement>, row: RegDInterestedClient) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
};
  
  const handleCloseEllipsis = () => {
    setAnchorEl(null);
};

const handleOpenConfirmationDialog = (row: RegDInterestedClient | null, title: string, message: string) => {
  if (!row) return;
  setSelectedRow(row);
  setConfirmationDialogConfig({ title, message });
  setOpenConfirmationDialog(true);
};

  const handleFileChange = useCallback(async (r: RegDInterestedClient, e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }

    if (!invalidFileDataTest(file)) {
      setError("Invalid file data.");
      return;
    }

    if (!largeFileTest(file)) {
      setError("File size must be under 10 megabytes.");
      return;
    }
    try {
      const fileName = `${r.id}_${opportunity?.name}_${r.account_name}_Subscription_Agreement.pdf`;
      const documentId = await createDocument(file, {
        customerId: r.client_id,
        documentTypeId: DocumentTypes.SubscriptionAgreements,
        fileName,
        investorId: r.id,
        investmentCompanyId: +investmentId,
      });

      await apiClient.post('/documents/customers-documents/:p0', {
        data: {
          investmentCompanyId: +investmentId,
          investorId: r.id,
          documentId
        },
        routeParams: [r.client_id]
      });
      
      if (r.status_id !== InvestorStatus.Committed) {
        await apiClient.patch('/investors/:p0/confirmed', { routeParams:[r.id]});
      }

      const item = regDInterestedList.find(lineItem => lineItem.id === r.id);

      if (!item) {
        throw new Error("Error while fetching uploaded document.")
      }
      item.subscription_document_id = documentId;
      setSuccess("Document uploaded successfully.");
    } catch (error) {
      let message = "An error occurred while uploading the document."
      
      if (isAxiosError(error)) {
        message = error.response?.data?.message ?? error.message;
      } else if (error instanceof Error) {
        message = error.message;
      }

      setError(
        <div>
          <Typography variant="h6">Error:</Typography>
          <Typography variant="body1">{message}</Typography>
          <Typography variant="body1">Please contact <ErrorContactEmail /> for assistance.</Typography>
        </div>
      )
    }
  }, [regDInterestedList, opportunity?.name, investmentId, setError, setSuccess]);


const handleCloseConfirmationDialog = (success?: boolean) => {
  if(!confirmationDialogConfig || !selectedRow) return;
  setOpenConfirmationDialog(false);
  if (success) {
    if(confirmationDialogConfig.title === "Delete") {
      apiClient.delete(`/investors/investment-companies/:p0/investors/:p1`, { data: {}, routeParams: [investmentId!, selectedRow.id] })
        .then(() => {
          setRegDInterestedList(list => list.filter(row => row.id !== selectedRow.id));
          setAnchorEl(null);
          setSuccess("Investor deleted successfully");
          setTimeout(() => {
            setSuccess('');
          }, 3000);
        })
        .catch(error => {
          setError(error.message || "An error occurred while deleting the record.");
        });
      }
    }
    setConfirmationDialogConfig(null);
  } ;

  useEffect(() => {
    const filteredData = regDInterestedList.filter((row) => {
      switch (selectedCategory) {
        case 'Document Status':
          const documentStatus = getDocumentStatus(row);
          return selectedFilterOptions.includes(documentStatus);
        case 'Monies Status':
          return selectedFilterOptions.includes(row.monies_status ? MoniesStatus.Received : MoniesStatus.NotReceived);
        default:
          return true;
      }
    }).filter(row => {
      return row.client_name.toLowerCase().includes(searchFilter.toLowerCase());
    });
    setFilteredRows(filteredData);
}, [selectedFilterOptions, regDInterestedList, selectedCategory, searchFilter]);

  const handleSave = async(row: RegDInterestedClient) => {
    try {
      setLoading(true)
      if(!subscriptionAmount) return;

      if(subscriptionAmount < minimumCommitment!) {
        setError(`Commitment must be more than ${toCurrency(minimumCommitment)}`)
        return;
      }
      
      const routeParams =[investmentId!, rowId!]
      const payload: InvestorUpdate ={
        subscriptionAmount,
        fundId: investmentId,
        professionalId: user?.id
      }
      const res= await apiClient.patch('/investors/investment-companies/:p0/investors/:p1', { data: payload, routeParams });
      setFilteredRows(res)
      setSuccess("Commitment Amount Updated")
    } catch (error) {
      setError("Error While Updating")
    }finally{
      setRowId(null) 
      setLoading(false)
    }
  };

  const handleClose = () => {
    setSubscriptionAmount(null);
    setRowId(null);
  };


  const displayEditableCurrency = (v: number | string | null, row: RegDInterestedClient) => {
    if (row.account_id == null) {
      return '$0';
    }
  
    const viewBox = (
      <Box
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          setRowId(row?.id);
          setSubscriptionAmount(null);
        }}
      >
        <Link>{v !== null ? toCurrency(v, true) : '---'}</Link>
      </Box>
    );
  
    const editBox = (
      <Box sx={{ display: 'flex' , alignItems:"center"}}>
        <CurrencyFieldText 
          sx={{ width: '90px', p: 0, mr: 1, height: 40}}
          defaultValue={typeof v === 'number' ? v.toLocaleString() : ''}
          variant="standard"
          onValueChange={(val:any) => setSubscriptionAmount(val.floatValue)}
        />
        <IconButton 
          disabled={isNullish(subscriptionAmount)} // Disable only if subscriptionAmount is nullish
          onClick={() => handleSave(row)}
        >
          <CheckIcon fontSize="small" sx={{ color: !isNullish(subscriptionAmount) ? 'green' : 'grey', m: 0 }}/>
        </IconButton>
        <IconButton onClick={handleClose}>
          <CloseIcon fontSize="small" sx={{color:"red", m: 0}}/>
        </IconButton>
      </Box>
    )
    
    if (v == null) return '---';
  
    if (!row.docs_received) {
      return (
        <Box sx={{position: 'relative'}}>
          {rowId === row.id ? 
          <>
            {editBox}
            {!isNullish(subscriptionAmount)  && <Box sx={{position: 'absolute', bottom: -14}}>
            </Box>}
          </> :
          (viewBox)}
        </Box>
      )
    } else {
      return toCurrency(v);
    }
  };
  


  const displayDocumentStatus = (_value: any, row: RegDInterestedClient) => {
    if (row.docs_received) {
      return DocumentStatusChips.Completed;
    } else if (row.subscription_document_id) {
      return DocumentStatusChips.Submitted;
    } else {
      return DocumentStatusChips.NotReceived;
    }
  }

  const displayMoniesStatus = (moniesStatus: string | Date | null) => {
    return moniesStatus ? MoniesStatusChips.Received(formatDate(moniesStatus)) : MoniesStatusChips.NotReceived;
  }

  const handleUnknownAccountClick = (row: RegDInterestedClient) => {
    setSelectedRow(row);
    setOpenAddInvestorDialog(true);
  };

  const displayClientName = (v: string | null, row: RegDInterestedClient) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
        {row.origin === 'Vision' && (
          <Box sx={{
            width: 8,
            height: 8,
            backgroundColor: 'orange',
            borderRadius: '50%',
            marginRight: 1,
          }} />
        )}
        {v}
      </Box>
    );
  };

  const displayAccountName = (v: string | null, row: RegDInterestedClient) => {
    if (row.account_id) {
      return v || '---';
    } else {
      return (
        <Button
          variant="text"
          onClick={() => handleUnknownAccountClick( row)}
          sx={{
            color: 'warning.main',
            textTransform: 'none',
            padding: 0,
            '&:hover': {
              backgroundColor: 'transparent',
          },
        }}
      >
        Account Not Linked
      </Button>
    );
  }
};

  const distributionsColumnDef: ColumnDef<RegDInterestedClient & { actions: never }> = [
    { id: 'client_name', label: "Client Name", displayFn: (v: string | null, row: RegDInterestedClient) => displayClientName(v, row)},
    { id: 'account_name', label: "Account Name", displayFn: displayAccountName },
    { id: 'commitment_amount', label: "Commitment Amount", displayFn: (v: number | string | null, row: RegDInterestedClient) => displayEditableCurrency(v,row), showTotal: true,totalDisplayFn: (v: number | string) => toCurrency(v,true) },
    {
      id: 'subscription_document_id',
      label: "Subscription Document",
      displayFn: (v, r) => (
        <DownloadUploadSplitButton
          accept="application/pdf"
          documentId={v}
          uploadDisabled={!!r.docs_received || !r.account_id}
          handleFileChange={(e) => handleFileChange(r, e)}
        />
      )
    },
    { id: 'docs_received', label: "Document Status", displayFn: displayDocumentStatus },
    { id: 'monies_status', label: "Monies Status", displayFn: (v: string | Date | null) => displayMoniesStatus(v) },
    { id: 'actions', label: "Actions", displayFn: (_,row: RegDInterestedClient) => (
      <React.Fragment>
        <IconButton
          onClick={(e) => handleClickEllipsis(e, row)}
          aria-controls="simple-menu"
          aria-haspopup="true"
        >
          <MoreVertIcon />
        </IconButton>
      </React.Fragment>
    )}
  ];    

  const handleAddInvestorClose = (result?: boolean, payload?: Record<string, any>) => {
    setOpenAddInvestorDialog(false);

  if (result && payload && selectedRow) {
    setRegDInterestedList(prevList =>
      prevList.map(r =>
        r.id === selectedRow.id
          ? { ...r, ...payload }
          : r
      )
    );
    setSuccess("Investor added successfully");
    setSelectedRow(null); 
  }
};
  
  return (
    <Box component="section" mb={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <Typography variant="h5">Interest List</Typography>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <FormControl sx={{mr: 1}}>
              <Select
                size="small"
                value={selectedCategory}
                onChange={(event: SelectChangeEvent<keyof FilterOptions>) => handleCategoryChange(event)}
                placeholder="Filter by Category"
                
                renderValue={(selected) => selected}
                MenuProps={{
                  PaperProps: {
                    style: {
                      width: 250,
                      maxHeight: 500,
                    },
                  },
                }}
              >
                {Object.entries(filterOptions()).map(([category, options]) => (
                      <MenuItem key={category} value={category}>{category}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{mr: 1}}>
              <Select
                multiple
                size="small"
                sx={{minWidth: 200}}
                value={selectedFilterOptions}
                displayEmpty
                onChange={(event: SelectChangeEvent<number[]>) => handleOptionChange(event)}
                placeholder="Filter by Status"
                renderValue={(selected) => {
                  if (selected.length === 1) {
                    return <Typography sx={{color: grey[500]}}>{selected.length} Status Selected</Typography>
                  }
                  return selected.length > 0 ? 
                <Typography sx={{color: grey[500]}}>{selected.length} Statuses Selected</Typography> : <Typography sx={{color: grey[500]}}>Filter By Statuses</Typography>}}
                MenuProps={{
                  PaperProps: {
                    style: {
                      width: 250,
                      maxHeight: 500,
                    },
                  },
                }}
              >
                {filterOptions()[selectedCategory].map((option) => (
                  <FormControlLabel
                    key={option.value}
                    sx={{m: 1}}
                    control={
                      <Checkbox sx={{py:1, mr:1}} checked={selectedFilterOptions.includes(option.value)} onChange={(event: ChangeEvent, checked) => handleFilterChange(checked, option)} name={option.value.toString()} />
                    }
                    label={option.label}
                  />
                ))}
              </Select>
            </FormControl>
            <TextField
              value={searchFilter}
              onChange={(event) => setSearchFilter(event.target.value)}
              placeholder="Search Interest List"
              size="small"
              helperText=""
              InputProps={{
                startAdornment: <InputAdornment position="start"><Search /></InputAdornment>,
              }} />
          </Box>
        </Box>
      <TableContainer component={Paper}>
        <DataSourceTable columnDef={distributionsColumnDef} dataSource={filteredRows} emptyMessage="No clients" showTotals rowState={rowState} onRowStateChange={handleRowStateChange}/>
        <ConfirmationDialog
          open={openConfirmationDialog}
          handleClose={handleCloseConfirmationDialog}
          message={confirmationDialogConfig?.message || ''}
          titleNode={confirmationDialogConfig?.title || ''}
          yesButtonLabel="Confirm"
          noButtonLabel="Cancel"
        />
      </TableContainer>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseEllipsis}
          >
          <MenuItem 
            onClick={() => 
              handleOpenConfirmationDialog(selectedRow, 'Delete', `You are about to delete ${selectedRow ? (selectedRow.account_name || selectedRow.client_name) : 'the selected account'} from the Interest List.`)}
          >
            Delete
            </MenuItem>
        </Menu>
        {selectedRow && (
          <InvestorDialog
            mode='edit'
            open={openAddInvestorDialog}
            handleClose={handleAddInvestorClose}
            setRefreshDataTrue={setRefreshDataTrue}
            investedList={investors}
            interestedList={regDInterestedList}
            preSelectedClient={clientsMap[selectedRow.client_id] || undefined}
            existingInvestorData={selectedRow}
          />
        )}
      </Box>
    )
  }

const DocumentStatusChips = {
  NotReceived: <Chip
    color="info"
    clickable={false}
    size="small"
    label="Not Received"
    variant="outlined"
  />,
  Submitted: <Chip
    color="info"
    clickable={false}
    size="small"
    label="Submitted"
    variant="outlined"
  />,
  Completed: <Chip
    color="success"
    clickable={false}
    size="small"
    label="Completed"
    variant="outlined"
  /> 
}

const MoniesStatusChips = {
  NotReceived: (
    <Chip
      color="info"
      clickable={false}
      size="small"
      label="Not Received"
      variant="outlined"
    />
  ),
  Received: (date: string | Date) => (
    <Chip
      color="success"
      clickable={false}
      size="small"
      label={`${date}`}
      variant="outlined"
    />
  )
};

const filterOptions: () => FilterOptions = () => ({
  "Document Status": [
    { value: DocumentStatus.NotReceived, label: DocumentStatusChips.NotReceived },
    { value: DocumentStatus.Submitted, label: DocumentStatusChips.Submitted },
    { value: DocumentStatus.Completed, label: DocumentStatusChips.Completed },
  ],
  "Monies Status": [
    { value: MoniesStatus.NotReceived, label: MoniesStatusChips.NotReceived },
    { value: MoniesStatus.Received, label: MoniesStatusChips.Received('Received') },
  ],
});

const getDocumentStatus = (r: RegDInterestedClient) => {
  if (r.docs_received) {
    return DocumentStatus.Completed;
  } else if (r.subscription_document_id) {
    return DocumentStatus.Submitted;
  } else {
    return DocumentStatus.NotReceived;
  }
}
