import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LockIcon from '@mui/icons-material/Lock';
import { Collapse, StepIconProps } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { grey } from '@mui/material/colors';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import { Fragment, ReactElement, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BaseSchema, object } from 'yup';
import { apiClient } from '../../../api/apiClient';
import { PostInterestedPartiesReq } from '../../../api/payloads';
import { Contact, PostContactReq, PostCustomerAccountReq, PostCustomerEntityReq, PostCustomerReq } from '../../../api/payloads/customer.payload';
import { LoadingButton } from '../../../components/Buttons';
import { ErrorToast } from '../../../components/ErrorToast';
import { FormikStepValidationHelper } from '../../../components/FormikStepValidationHelper/FormikStepValidationHelper';
import { useAuth, useClients, useInit, useMaskedParams, useProfessionals, useUI } from '../../../hooks';
import { useNewClient } from '../../../hooks/ContextHooks/useNewClient';
import { createDocument } from '../../../services/document.service';
import { AccountType, DocumentTypes, GrantorStrings, ProfessionalRelationship, RevocableStrings } from '../../../utility/constants';
import { maskRouteId } from '../../../utility/route-mask.util';
import { isAxiosError } from '../../../utility/type-guards/';
import { FormikSubmitFn } from '../../../utility/types';
import AccountForm from './Forms/AccountForm';
import EntityForm from './Forms/EntityForm';
import HouseholdFormFields from './Forms/HouseholdFormFields';
import MemberForm from './Forms/MemberForm';
import { accountFormSchema, AccountFormValues, entityDocumentFormSchema, EntityDocumentFormValues, householdFormSchema, initialAccountValues, initialDocumentValues, initialEntityValues, initialHouseholdValues, initialMemberValues, initialProfessionalValues, memberFormSchema, newClientFormSchema, NewClientFormValues, professionalFormSchema } from './Forms/newClientFormSchema';
import ProfessionalsForm from './Forms/ProfessionalsForm';
import ReviewHouseholdForm from './Forms/ReviewHouseHoldForm';

type TStep = {
  label: string,
  component: ReactElement,
  optional?: boolean,
  schema: BaseSchema,
}

export default function NewClientStepper() {
  const {
    accreditationValues, 
    customerID, 
    entityTypeValues, 
    newMembers,
    custodians,
    setAccountTypeValues, 
    setAccreditationValues, 
    setCountryCodeValues, 
    setCustodians, 
    setCustomerID, 
    setEntityTypeValues,
    setIraTypes,
    setNewEntity, 
    setNewMembers, 
    setPhoneTypeValues 
  } = useNewClient();
  const [activeStep, setActiveStep] = useState(0);
  const { clients,setClients } = useClients();
  const {advisory} = useProfessionals();
  const [skipped, setSkipped] = useState(new Set<number>());
  const { loading, setLoading, setError, setSuccess } = useUI();
  const {user} = useAuth();
  const navigate = useNavigate();
  const {householdId} = useMaskedParams()
  const { state } = useLocation();

  useEffect(() => {
    if (householdId != null) {
      setActiveStep(3)
    }
  },[householdId])

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps: TStep[] = [ 
    {
      label:'Household Details',
      component: <HouseholdFormFields />,
      schema: object(householdFormSchema),
    },
    {
      label: 'Member Detail',
      component: <MemberForm />,
      schema: object(memberFormSchema),
    },
    {
      label: 'Add Professionals',
      component: <ProfessionalsForm />,
      schema: object(professionalFormSchema),
    },
    {
      label: 'Review & Submit',
      component: <ReviewHouseholdForm handleBack={handleBack}/>,
      schema: newClientFormSchema,
    },
    {
      label: 'Entity Details',
      optional: true,
      component: <EntityForm accreditationValues={accreditationValues} entityTypeValues={entityTypeValues} newMembers={newMembers}/>,
      schema: entityDocumentFormSchema,
    },
    {
      label:'Add New Account',
      component: <AccountForm />,
      schema: accountFormSchema,
    },
  ];

  const LockedStepIcon = (props: StepIconProps) => <LockIcon/>

  const handleSkip = () => {
    if (steps[activeStep].optional !== true) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  useInit(async () => {
    const resp = await apiClient.get("/customers/new-client-field-values");
    const accountTypes = await apiClient.get('/customers/accounts/types');
    setAccreditationValues(resp.accreditations);
    setCountryCodeValues(resp.countries);
    setCustodians(resp.custodians)
    setEntityTypeValues(resp.entityTypes);
    setPhoneTypeValues(resp.phoneNumberTypes);
    setAccountTypeValues(accountTypes.accountTypes);
    setIraTypes(accountTypes.iraTypes);
  });
  const newClientInitialValues = useMemo((): NewClientFormValues => {
    if (user != null) {
      const initialProfessionalValues = {
        professionals: [{professionalId: user.id, receivesComms: false}],
        primaryProfessionalId: user.id,
      }

      return {
        ...initialHouseholdValues,
        ...initialMemberValues,
        ...initialProfessionalValues
      }
    }
    return {
      ...initialHouseholdValues,
      ...initialMemberValues,
      ...initialProfessionalValues
    }
  }
  , [user]);

  const entityAndDocumentInitialValues = useMemo((): EntityDocumentFormValues => ({
    ...initialEntityValues,
    ...initialDocumentValues,
  }), [])

  const accountInitialValues = useMemo((): AccountFormValues => ({
    ...initialAccountValues
  }), [])

  const handleSubmit: FormikSubmitFn<NewClientFormValues> = async (values, { setErrors ,setSubmitting, setFieldError }) => {
    if (advisory == null)  {
      return;
    }

    try {
      setLoading(true);
      const newContacts: Contact[] = [];
      const {members, ...rest} = values;

      const createCustomerData: PostCustomerReq = {
        name: rest.householdName!,
        address: {
          city: rest.city!,
          street_address_1: rest.streetAddress1!,
          street_address_2: rest.streetAddress2 ?? undefined,
          state_id: +rest.stateId!,
          zip_code: rest.zipCode.toString()!
        },
        primary_advisor_id: rest.primaryProfessionalId!,
      }

      const { customerId: respCustomerID, name: respName } = await apiClient.post('/customers/', {data: createCustomerData});
      setCustomerID(respCustomerID)
      const newClient = {
        id: respCustomerID,
        name: respName ?? "",
        number_of_investments: 0,
        total_commitment: 0,
        total_paid: 0,
        total_efv: 0,
      }
      setClients([...clients, newClient])

      const payload: PostInterestedPartiesReq = values.professionals.map((professional) => ({
        professional_id: professional.professionalId,
        advisory_id: advisory?.id,
        customer_id: respCustomerID,
        professional_relationship_id: ProfessionalRelationship.Advisor,
        receives_email_notices: professional.receivesComms,
      }));

      await apiClient.post("/professionals/interested-parties", {data: payload});

      for (const m of members) {
        let documentId: number | undefined;

        if (m.idImageFile && m.expirationDate) {
          const file = m.idImageFile as File;
          documentId = await createDocument(file, {
            customerId: respCustomerID,
            fileName: file.name,
            documentTypeId: DocumentTypes.Identification,
          });
        }

        const [dial_code, country_region] = m.countryCode.split(';');
        const createContactReq: PostContactReq = {
          customerId: respCustomerID,
          member: {
            first_name: m.firstName,
            nickname: m.nickname,
            middle_name: m.middleName,
            last_name: m.lastName,
            date_of_birth: m.dateOfBirth!.toISOString(),
            email: m.email,
            ssn: m.SSN,
            accreditation: Number(m.accreditation),
          },
          phone: {
            number: m.phoneNumber,
            contact_number_types: +m.contactNumberType,
            dial_code,
            country_region
          }
        }

        const newContact = await apiClient.post("/customers/contacts", {data: createContactReq});
        newContacts.push(newContact);

        if (documentId && m.expirationDate) {
          await apiClient.post('/documents/customers-documents/:p0/contact/:p1', {
            routeParams: [respCustomerID, newContact.id],
            data: {
              expirationDate: m.expirationDate.toISOString(),
              documentId,
            },
          });
        }     
      }

      setNewMembers(newContacts); // Will set new clients with response from submission
      setSuccess("Successfully added household and member(s)")
      setLoading(false);
      handleNext();
    } catch (e) {
      console.log(e);
      setError("Oops, something went wrong. Please try again later")
      setLoading(false)
    }
  };

  const handleEntitySubmit: FormikSubmitFn<EntityDocumentFormValues> = async (values, { setErrors, setSubmitting, setFieldError }) => {
    if (customerID == null) {
      return;
    }

    setLoading(true);
    try {
      const { entityOwners, ...rest } = values

      const createEntity: PostCustomerEntityReq = {
        entity: {
          accreditation_id: Number(rest.qualification),
          customer_id: customerID,
          ein: Number(rest.EIN),
          is_grantor: rest.grantor === GrantorStrings.Grantor,
          is_revocable: rest.revocable === RevocableStrings.Revocable,
          name: rest.entityName,
          type: Number(rest.entityType),
        },
        owners: entityOwners.map(o => ({
          contact_id: Number(o.id),
          percentage: Number(o.percentage)
        })),
      }

      let formationDocumentId: number | undefined;
      let governingDocumentId: number | undefined;
      let trustDocument: number | undefined;

      if (rest.formationDocuments && rest.formationDocuments instanceof File) {
        formationDocumentId = await createDocument(rest.formationDocuments, {
          customerId: customerID,
          documentTypeId: DocumentTypes.FormationDocuments,
          fileName: rest.formationDocuments.name,
        })
      }

      if (rest.governingDocuments && rest.governingDocuments instanceof File) {
        governingDocumentId = await createDocument(rest.governingDocuments, {
          customerId: customerID,
          documentTypeId: DocumentTypes.GoverningDocuments,
          fileName: rest.governingDocuments.name,
        })
      }

      if (rest.trustDocument && rest.trustDocument instanceof File) {
        trustDocument = await createDocument(rest.trustDocument, {
          customerId: customerID,
          documentTypeId: DocumentTypes.TrustDocuments,
          fileName: rest.trustDocument.name,
        })
      }

      const newEntityResp = await apiClient.post("/customers/entities", { data: createEntity });
      setNewEntity(newEntityResp);

      if (formationDocumentId) {
        await apiClient.post('/documents/customers-documents/:p0/entity/:p1', {
          routeParams: [customerID, newEntityResp.entityId],
          data: { documentId: formationDocumentId },
        });
      }

      if (governingDocumentId) {
        await apiClient.post('/documents/customers-documents/:p0/entity/:p1', {
          routeParams: [customerID, newEntityResp.entityId],
          data: { documentId: governingDocumentId },
        });
      }

      if (trustDocument) {
        await apiClient.post('/documents/customers-documents/:p0/entity/:p1', {
          routeParams: [customerID, newEntityResp.entityId],
          data: { documentId: trustDocument },
        });
      }

      setLoading(false);
      setSuccess("Successfully added entity")
      handleNext();
    } catch (e) {
      setError("Oops, something went wrong. Please try again later")
      setLoading(false)
    }
  };

  const handleAccountSubmit: FormikSubmitFn<AccountFormValues> = async (values, { setErrors ,setSubmitting, setFieldError }) => {
    if (customerID == null)  {
      return;
    }
    setLoading(true);
    try {
      const {clientAccountMembers, accountType, custodian,  ...rest} = values

      const createAccount: PostCustomerAccountReq  = {
        accountData: {
          account_name: rest.accountName,
          account_type_id: accountType,
          customer_id: customerID
        },
        owners: [],
      }
      const selectedCustodian = custodians.find(c => c.id === Number(custodian));
      const isNonQualifiedCustodian = selectedCustodian?.non_qualified === true;

      if (accountType === AccountType.IRA) {
        createAccount.accountData.custodian_id = Number(custodian);
        createAccount.accountData.account_number = rest.accountNumber;
        createAccount.accountData.ira_type_id = rest.iraTypeId as number;
        createAccount.owners?.push({family_member_id: Number(rest.beneficiary), ownership: 100})
      } else {
        if (isNonQualifiedCustodian ) {
          createAccount.accountData.account_number = rest.accountNumber;
          createAccount.accountData.custodian_id = Number(custodian);
        }
        switch (accountType) {
          case AccountType.Individual:
            createAccount.accountData.ssn_contact_id = Number(rest.clientAccountMember);
            createAccount.owners?.push({ family_member_id: Number(rest.clientAccountMember), ownership: 100 });
            break;
          case AccountType.Joint:
            clientAccountMembers.forEach(m => {
              createAccount.owners?.push({ family_member_id: Number(m), ownership: 50 });
              });
            createAccount.accountData.ssn_contact_id = Number(rest.ssnContactId);
            break;
          case AccountType.Entity:
            createAccount.accountData.customer_entity_id = Number(rest.entityAccount);
            break;
        }
      }

      await apiClient.post("/customers/accounts", {data: createAccount});

      setSuccess("Successfully added account");
      setLoading(false);
      if (state?.investmentId && state?.tab) {
        navigate(`/opportunities/${maskRouteId(state.investmentId)}/${state.tab}`,{state: {openAddInvestor: true}})
      } else {
        navigate("/clients")
      }
    } catch (error) {
      if (isAxiosError(error)) {
        const isInternalError = error.response?.status === 500;
        const message = error.response?.data?.message ?? error.message;
        setError(<ErrorToast showContactEmail={isInternalError}>{message}</ErrorToast>)
      } else {
        console.log(error);
        setError(<ErrorToast showContactEmail>An unexpected error occurred.</ErrorToast>)
      }
      setLoading(false)
    }
  };

  return (
    <Box sx={{ width: '100%', minWidth: 800 }}>
      <Stepper 
        activeStep={activeStep} 
        sx={
          activeStep <= 3 ? 
          {width: '100%', justifyContent: 'space-between'}
          :
          {width: '100%',}
        } 
        connector={<Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><ChevronRightIcon sx={{color: 'grey',fontSize: 30}}/></Box>}
      >
        {steps.map((step, index) => {
          const stepProps: { completed?: boolean } = {};
          let locked = false
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          if (activeStep > 3 && index <= 3) {
            locked = true
          }
          if (activeStep > 4 && index <= 4) {
            locked = true
          }
          
          return (
            <Step key={step.label} {...stepProps} sx={{position: 'relative'}}>
              {locked ? 
                <StepLabel 
                  StepIconComponent={LockedStepIcon}
                  sx={{color: grey[500]}}>
                  <Collapse orientation="horizontal" in={!locked}>
                    {step.label}
                  </Collapse>
                  </StepLabel>
                :
                <StepLabel >
                  <Collapse orientation="horizontal" in={!locked}>
                  {step.label}
                  </Collapse>
                  </StepLabel>
              }
              {step.optional === true && !locked && <Typography sx={{position: 'absolute', left: 40, bottom: -14, fontSize: 11}} variant="caption">Optional</Typography>}
            </Step>
          );
        })}
      </Stepper>
      <Fragment>
        <Box sx={{pt: 4}}>
        {activeStep <= 3 ? 
          <Formik 
            key={"householdForm"} // Key prop is necessary for Formik Context to distinguish the two forms
            initialValues={newClientInitialValues} 
            validationSchema={steps[activeStep].schema} 
            validateOnMount={true}
            validateOnChange={true}
            onSubmit={handleSubmit}>
            {({isValid,isSubmitting}) => {
              return (
                <Form>
                <FormikStepValidationHelper activeStep={activeStep}/>
                <Box>
                  {steps[activeStep].component}
                  {activeStep === 3 ?
                    <Box sx={{display: 'flex', justifyContent: "space-between"}}>
                      <Button
                          color="inherit"
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                        >
                          Back
                      </Button>
                      <LoadingButton type="submit" size="large" loading={loading} disabled={!isValid}>
                        Submit & Continue
                      </LoadingButton>
                    </Box>
                  :
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      {activeStep !== 0 && <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Back
                      </Button>}
                      <Box sx={{ flex: '1 1 auto' }} />
                      <LoadingButton loading={isSubmitting}  color='info' 
                       onClick={handleNext} 
                       disabled={!isValid}>
                        Next
                      </LoadingButton>
                    </Box>
                  }
                </Box>
              </Form>
            )}}
          </Formik>
          :
          activeStep === 4 ?
          <Formik 
            key={"entityForm"} // Key prop is necessary for Formik Context to distinguish the two forms
            initialValues={entityAndDocumentInitialValues} 
            validationSchema={entityDocumentFormSchema} 
            onSubmit={handleEntitySubmit}>
              {({isValid}) => (
              <Form>
                <FormikStepValidationHelper activeStep={activeStep}/>
                <Box>
                  {steps[activeStep].component}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {steps[activeStep].optional === true && (
                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                      Skip
                    </Button>
                  )}
                  <LoadingButton type="submit" size="large" loading={loading} disabled={!isValid}>
                    Submit & Continue
                  </LoadingButton>
                </Box>
              </Form>
            )}
          </Formik>
          :
          <Formik 
            key={"accountForm"} // Key prop is necessary for Formik Context to distinguish the two forms
            initialValues={accountInitialValues} 
            validationSchema={accountFormSchema} 
            onSubmit={handleAccountSubmit}>
            {({isValid, isSubmitting}) => (
            <Form>
              <FormikStepValidationHelper activeStep={activeStep}/>
              <Box>
                {steps[activeStep].component}
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                {steps[activeStep].optional === true && (
                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )}
                <LoadingButton type="submit" size="large" loading={loading || isSubmitting} disabled={!isValid}>
                  Submit
                </LoadingButton>
              </Box>
            </Form>
            )}
          </Formik>
        }
        </Box>
      </Fragment>
    </Box>
  );
}