import { Box, MenuItem } from '@mui/material';
import { Field } from 'formik';
import { Select, TextField } from 'formik-mui';
import { FC } from 'react';
import { DistributionInstructionAccountType } from '../../../../../../utility/constants';
import { DocumentFileUploadField } from '../../CustomFields/DocumentFileUploadField';


export const ACHInstructionFields: FC = () => {
  return (
    <Box sx={{mt: 4, mb:2}}>
      <Box>
        <Field name="achInstructions.instructionName" required label="Distribution Instruction Friendly Name" component={TextField} sx={{width: '50%', mr: 2, ml:2}}/>
        <Field name="achInstructions.bankAccountName" required label="Name on Bank Account" component={TextField} sx={{width: '40%', ml: 1}}/>
      </Box>
      <Box>
        <Field name="achInstructions.aba" required label="Bank's Routing Number" component={TextField} sx={{width: '50%', mr: 2, ml:2, mt:2}}/>
        <Field name="achInstructions.accountNumber" required label="Bank Account Number" component={TextField} sx={{width: '40%', ml: 1, mt:2}}/>
      </Box>
      <Box>
      <Field 
          name="achInstructions.accountType" 
          label="Account Type" 
          component={Select} 
          formControl={{required: true, sx:{width: '30%', mr: 2, ml:2}}}
          onClose={() => {}}
          >
            {Object.entries(DistributionInstructionAccountType).map(([key, value]) => (
              <MenuItem key={value} value={value}>{key}</MenuItem>
            ))}
        </Field>
        <Field name="achInstructions.ffc" label="For Further Credit To" component={TextField} sx={{width: '60%', ml:1.5}}/>
        <DocumentFileUploadField
        accept="image/png,image/gif,image/jpeg,image/tiff,application/pdf"
        label="Attach Voided Check"
        name="achInstructions.voidedCheck"
        sx={{width: '30%', mr: 2, ml: 2, mt:2}}
        />
      </Box>
    </Box>
  );
}