import { Box, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import { Field, Form, useFormikContext } from "formik";
import { Select } from "formik-mui";
import { useEffect, useMemo } from "react";
import { Custodian } from "../../../../api/payloads/customer.payload";
import { LoadingButton } from "../../../../components/Buttons";
import { FormikStepValidationHelper } from "../../../../components/FormikStepValidationHelper/FormikStepValidationHelper";
import { useClientDetails } from "../../../../hooks";
import { AccountType } from "../../../../utility/constants";
import { BaseRecord } from "../../../../utility/types";
import EntityAccountFields from "../../NewClientPage/Forms/EntityAccountFields";
import IndividualAccountFields from "../../NewClientPage/Forms/IndividualAccountFields";
import IRAAccountFields from "../../NewClientPage/Forms/IRAAccountFields";
import JointAccountFields from "../../NewClientPage/Forms/JointAccountFields";
import { AccountFormValues } from "../../NewClientPage/Forms/newClientFormSchema";

const NOT_CUSTODIAN_ACCOUNT = "not_custodied";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "100px",
    },
  },
};

const nonIraAccountTypes = [
  AccountType.Individual,
  AccountType.Joint,
  AccountType.Entity,
];

const getAccountTypesForCustodian = (custodian: Custodian) => {
  const accountTypes = [];

  if (custodian.qualified) {
    accountTypes.push(AccountType.IRA);
  }

  if (custodian.non_qualified) {
    accountTypes.push(...nonIraAccountTypes);
  }

  return accountTypes;
};

type Props = {
  isLoading: boolean,
  custodians: Custodian[],
  accountTypeValues: BaseRecord[],
  iraTypes: BaseRecord[],
}

export default function AddAccountFormFields({ isLoading, custodians, accountTypeValues, iraTypes }: Props) {
  const { values, isValid, setFieldValue } = useFormikContext<AccountFormValues>();
  const { activeClient } = useClientDetails();
  const { accountType, custodian } = values

  const isNotCustodiedSelected = custodian === NOT_CUSTODIAN_ACCOUNT;
  const isAnyCustodianSelected = !isNotCustodiedSelected && !!custodian

  const selectedCustodian = useMemo(() =>
    isNotCustodiedSelected ? null : custodians.find(c => String(c.id) === String(custodian)),
    [custodian, custodians, isNotCustodiedSelected]
  );

  const validAccountTypes = useMemo(() => {
    if (isNotCustodiedSelected) {
      return [...nonIraAccountTypes];
    }
    
    if (!selectedCustodian) return [];


    return getAccountTypesForCustodian(selectedCustodian);
  }, [isNotCustodiedSelected, selectedCustodian]);

  /** 
   * @todo: consider revising this useEffect and the validAccountTypes useMemo to be a single event handler
   */ 
  useEffect(() => {
    // Handle clearing account type if the selected custodian does not support the selected account type
    if (!validAccountTypes.includes(accountType)) {
      setFieldValue('accountType', "");
    }
  }, [validAccountTypes, accountType, setFieldValue])

  const appropriateFields = useMemo(() => {
    switch (accountType as AccountType) {
      case AccountType.IRA:
        return <IRAAccountFields iraTypes={iraTypes} members={activeClient?.contacts ?? []} custodian={selectedCustodian!} />;
      case AccountType.Individual:
        return <IndividualAccountFields members={activeClient?.contacts ?? []} custodian={selectedCustodian!} />;
      case AccountType.Joint:
        return <JointAccountFields members={activeClient?.contacts ?? []} custodian={selectedCustodian!} />;
      case AccountType.Entity:
        return <EntityAccountFields defaultEntity={null} entities={activeClient?.entities ?? null} custodian={selectedCustodian!} />;
      default:
        return null
    }
  }, [accountType, activeClient, iraTypes, selectedCustodian])

  const filteredCustodians = custodians.filter(c => c.qualified || c.non_qualified);
  const filteredAccountTypes = accountTypeValues.filter(a => validAccountTypes.includes(a.id));

  return (
    <Form>
      <FormikStepValidationHelper activeStep={0} />
      <DialogContent style={{ paddingTop: '0.5rem' }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field
              formControl={{ required: true, fullWidth: true }}
              component={Select}
              label="Custodian Name"
              MenuProps={MenuProps}
              name="custodian"
            >
              <MenuItem value="not_custodied" style={{ fontWeight: 'bold' }}>Not Custodied</MenuItem>
              {filteredCustodians.map(c => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)}
            </Field>
          </Grid>
          {custodian && (
            <Grid item xs={6}>
              <Field
                formControl={{ required: true, fullWidth: true }}
                component={Select}
                label="Account Type"
                MenuProps={MenuProps}
                name="accountType"
                onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
              >
                {filteredAccountTypes.map(a => <MenuItem key={a.id} value={a.id}>{a.name}</MenuItem>)}
              </Field>
            </Grid>
          )}

        </Grid>
        {values.accountType && (
          <Box sx={{ py: 2 }}>
            <Typography variant="h5" sx={{ pb: 2 }}>Details</Typography>
            <Box>
              {appropriateFields}
            </Box>
          </Box>
        )}
        {isAnyCustodianSelected && (
          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <Typography variant="body2" color="text.secondary">
              The Custodian may charge a fee for alternative investments. Please contact the Custodian for more information.
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton disabled={!isValid} color='info' type='submit' loading={isLoading}>Submit</LoadingButton>
      </DialogActions>
    </Form>
  );
}