import { Box, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, MenuItem, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { Select } from "formik-mui";
import moment from "moment";
import { FC, useCallback, useMemo, useState } from "react";
import { array, boolean, date, mixed, number, object, string } from "yup";
import { apiClient } from "../../../../../api/apiClient";
import { Accreditation, Contact, PhoneNumber } from "../../../../../api/payloads/customer.payload";
import { CornerCloseButton, LoadingButton } from "../../../../../components/Buttons";
import { ErrorContactEmail } from "../../../../../components/ErrorContactEmail/ErrorContactEmail";
import { useInit, useMaskedParams, useUI } from "../../../../../hooks";
import { createDocument } from "../../../../../services/document.service";
import { partition } from "../../../../../utility/array.util";
import { DocumentTypes } from "../../../../../utility/constants";
import { invalidFileDataTest, largeFileTest, largeFileTestMessage } from "../../../../../utility/file.util";
import { isNullish } from "../../../../../utility/misc.util";
import { isString } from "../../../../../utility/string.util";
import { EditMemberFormFields } from "./EditMemberFormFields";
import { EditMemberFormIdentificationFields } from "./EditMemberFormIdentificationFields";

export const getEditMemberFormSchema = (showSelectVisionNumber: boolean) => {
  return object({
    first_name: string().required('First name is required'),
    nickname: string().nullable(),
    middle_name: string().nullable(),
    last_name: string().required('Last name is required'),
    email: string().email("Not a valid email").required('Email is required'),
    phone_numbers: array().of(object({
      number: string().matches(/^[-\d]+$/g, "Not a valid phone number").required('Phone number is required'),
      contact_number_type_id: number().required('Phone number type is required'),
      country_data: string().required("Country dialing code is required"),
      is_vision_number: boolean()
    }))
    .test('vision-number-required', "Please select a phone number for the member's Vision account", value => {
      return !showSelectVisionNumber || !!value?.some(pn => pn.is_vision_number)
    }),
    idImageFile: mixed().when('expirationDate', {
      is: (expirationDate: moment.Moment | null) => {
        return !isNullish(expirationDate)
      },
      then: mixed()
        .required("ID image is required")
        .test('invalidFileData', "Invalid file data", invalidFileDataTest)
        .test('fileSize', largeFileTestMessage, largeFileTest),
    }).nullable(), 
    imageId: number().nullable(),
    expirationDate: date()
    .typeError("Must be a valid date")
    .test('is-required', 'Expiration date is required', function (expirationDate) {
      if (!isNullish(this.parent.idImageFile)) {
        return !!expirationDate;
      }
      return true;
    })
    .min(new Date(), "Please pick a date in the future")
    .nullable(),
    accreditation: number().required("Accreditation is required"),
  });
}

export type EditMemberFormValues = {
  first_name: string;
  nickname: string;
  middle_name: string;
  last_name: string;
  email: string;
  phone_numbers: PhoneNumber[];
  idImageFile: File | {name: string} | null,
  imageId: number | null;
  expirationDate: moment.Moment | null,
  accreditation: number | string
}
  
export type EditMemberDialogProps = Omit<DialogProps, 'onClose'> & {
  contact: Pick<Contact,'id' | 'first_name' | 'nickname' | 'last_name' | 'middle_name' | 'email' | 'phone_numbers' | 'document_id' | 'photo_id_file_name' | 'doc_expiry_date' | 'accreditation'> | null,
  handleClose: (result?: boolean) => void
};

export const EditMemberDialog: FC<EditMemberDialogProps> = (props) => {
  let { contact, maxWidth, fullWidth, handleClose, ...rest } = props;
  const [loading, setLoading] = useState(false);
  const { setError } = useUI();
  maxWidth = maxWidth ?? "lg";
  const { clientId } = useMaskedParams();
  const [accreditationValues, setAccreditationValues] = useState<Accreditation[]>([]);
  
  const showSelectVisionNumber: boolean = useMemo(() => !!contact?.phone_numbers.find(pn => pn.is_vision_number), [contact])
  const EditMemberInitialValues: EditMemberFormValues = useMemo(() => ({
    first_name: contact?.first_name ?? "",
    nickname: contact?.nickname ?? "",
    middle_name: contact?.middle_name ?? "",
    last_name: contact?.last_name ?? "",
    email: contact?.email ?? "",
    phone_numbers: contact?.phone_numbers ?? [],
    idImageFile: contact?.photo_id_file_name ?  {name: contact?.photo_id_file_name} : null,
    imageId: contact?.document_id || null,
    expirationDate: contact?.doc_expiry_date ? moment(contact?.doc_expiry_date) : null,
    accreditation: contact?.accreditation ?? "",
  }), [contact]);

  useInit(async () => {
    if (accreditationValues.length === 0) {
      const resp = await apiClient.get("/customers/new-client-field-values");
      setAccreditationValues(resp.accreditations);
    }
  });

  const schema = useMemo(() => getEditMemberFormSchema(showSelectVisionNumber) ,[showSelectVisionNumber]);

  const handleSubmit = useCallback(
    async (values: EditMemberFormValues, helpers: FormikHelpers<EditMemberFormValues>) => {
      try {
        setLoading(true);
        const { email, phone_numbers, nickname, accreditation } = values;
        
        if (
          !isNullish(values.idImageFile) && 
          values.idImageFile instanceof File && 
          values.expirationDate &&
          !values.imageId
        ) {
          const file = values.idImageFile;

          const documentId = await createDocument(file, {
            fileName: file.name,
            customerId: +clientId,
            documentTypeId: DocumentTypes.Identification,
          });

          await apiClient.post('/documents/customers-documents/:p0/contact/:p1', {
            routeParams: [clientId, contact!.id],
            data: {
              expirationDate: values.expirationDate.toISOString(),
              documentId,
            },
          });
        }

        const processedPhoneNumbers = phone_numbers.map(p => {
          const { country_data, contact_number_type_id, contact_number_type, ...rest } = p;
          const [dial_code, country_region] = country_data.split(';');
          return { ...rest, dial_code, country_region, contact_number_types: contact_number_type_id };
        });

        const [patchedPhoneNumbers, newPhoneNumbers] = partition(processedPhoneNumbers, p => !!p.id);
        const deletedIds = EditMemberInitialValues.phone_numbers.filter(v => !patchedPhoneNumbers.some(p => p.id === v.id)).map(p => p.id);
        const routeParams = [contact!.id];
        const hasChanges = patchedPhoneNumbers.some((patchedNumber, index) => {
          const initialValue = EditMemberInitialValues?.phone_numbers[index];    
          return (
            patchedNumber.dial_code !== initialValue.dial_code ||
            patchedNumber.number !== initialValue.number ||
            +patchedNumber.contact_number_types !== +initialValue.contact_number_type_id //TODO: Fix the Selection bug that causes this to be a string
          );
        });

        if(EditMemberInitialValues?.email !== email || EditMemberInitialValues?.nickname !== nickname || EditMemberInitialValues?.accreditation !== accreditation) {
         await apiClient.patch('/customers/contacts/:p0', { data: { email, nickname, accreditation: +accreditation }, routeParams });
        }


        if (hasChanges){
          await apiClient.patch('/customers/contacts/:p0/phone-numbers', { data: patchedPhoneNumbers, routeParams });
        }
        newPhoneNumbers.length && await apiClient.post('/customers/contacts/:p0/phone-numbers', { data: newPhoneNumbers, routeParams });
        deletedIds.length && await apiClient.delete('/customers/contacts/:p0/phone-numbers', { data: deletedIds, routeParams });
        handleClose(true);
      } catch (e: unknown) {
        helpers.setSubmitting(false);

        if (e instanceof AxiosError && e.response?.status === 409) {
          setError(
            <div>
              <Typography variant="body1">
                The email address you entered is already in use. Please enter a different email address.
              </Typography>
            </div>
          );
          return;
        }
        setError(
          <div>
            <Typography variant="h6">Something went wrong:</Typography>
            <Typography variant="body1">
              We encountered a problem while updating this household member's contact information.
              Please contact <ErrorContactEmail /> for assistance.
            </Typography>
          </div>
        )
      } finally {
        setLoading(false);
      }
    }, [clientId, contact, handleClose, EditMemberInitialValues, setError]);

  return (
    <Dialog PaperProps={{ sx: { minWidth: '700px' } }} maxWidth={maxWidth} onClose={() => handleClose()} {...rest}>
      <DialogTitle>
        Edit Member
        <CornerCloseButton onClick={() => handleClose()}></CornerCloseButton>
      </DialogTitle>
      <Formik
        validateOnChange={true} // Performance hit when validating each nested phone number at the moment of value change. May be able to enhance with custom validator
        initialValues={EditMemberInitialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ isValid, errors }) => (
          <Form>
            <DialogContent style={{ paddingTop: '0.5rem' }}>
              <EditMemberFormFields {...{ showSelectVisionNumber }} />
              <Box sx={{mt: 2 , justifyContent: 'space-between', display: 'flex'}}>
              <Field formControl={{ required: true, sx: { minWidth: '240px' } }}
                component={Select} label="Accreditation" name="accreditation">
                {accreditationValues.map((a) => (
                  <MenuItem key={a.id} value={a.id}>
                    {a.name}
                  </MenuItem>
                ))}
              </Field>
                <EditMemberFormIdentificationFields label="Identification" />
              </Box>
            </DialogContent>
            <DialogActions>
              {!!errors.phone_numbers && isString(errors.phone_numbers) &&
                <Typography mr={1} color='error' variant="subtitle2">{errors.phone_numbers as string}</Typography>
              }
              <LoadingButton disabled={!isValid} color='info' type='submit' loading={loading}>Submit</LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}