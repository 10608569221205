import { Account, Custodian } from "../../../../api/payloads/customer.payload";
import { Opportunity } from "../../../../context/OpportunitiesContext";
import { AccountType } from "../../../../utility/constants";

function isAccountEligible(
  account: Account, 
  investmentCompany: Opportunity, 
  custodians: Custodian[]
): boolean {
  // Non-custodian accounts are always eligible
  if (!account.custodian_id) return true;

  // Check entity-level eligibility
  if (!isEntityEligible(account, investmentCompany)) return false;

  // Check global custodian overrides
  return isGloballyEligible(account, custodians);
}

function isEntityEligible(account: Account, investmentCompany: Opportunity): boolean {
  return investmentCompany.custodians.some((custodian) =>
    custodian.id === account.custodian_id &&
    custodian.accountTypes?.some((type) => type.id === account.account_type_id)
  );
}

function isGloballyEligible(account: Account, custodians: Custodian[]): boolean {
  const custodian = custodians.find((c) => c.id === account.custodian_id);
  if (!custodian) return false;

  const isIRA = account.account_type_id === AccountType.IRA;

  if (isIRA && custodian.qualified === false) return false;
  if (!isIRA && custodian.non_qualified === false) return false;

  return true;
}

export { isAccountEligible };
