import { Box, Container } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { apiClient } from "../../../api/apiClient";
import { ContentBackground } from "../../../components/ContentBackground/ContentBackground";
import { defaultErrorMessage, useClients, useInit, useUI, useMaskedParams, useClientDetails } from "../../../hooks";
import { ClientDetailHeader } from "./ClientDetailHeader";

/** 
 * Just a quick stop-gap to calm the React Linter's concern about 
 * a chance of calling useMemo every run. I've planned to remove 
 * this along with the clients map very soon
 */
const dummyObject = {};

export const ClientDetailPage: FC = () => {
  const { clientId } = useMaskedParams();
  const { clientsMap, addressStates, setAddressStates } = useClients();
  const { activeClient, setActiveClient } = useClientDetails();
  const {setError} = useUI();
  const [isInitialized, setIsInitialized] = useState(false);
  const client = clientsMap[+clientId!] ?? dummyObject; //Add client name to response for the clients service so that we don't need this map

  const init = useCallback(async () => {
    try {
      const resp = await apiClient.get("/customers/:p0", {routeParams: [clientId!]});
      setActiveClient(resp);
  
      if (addressStates === null) {
        const states = await apiClient.get("/customers/states");
        setAddressStates(states);
      }
      setIsInitialized(true);
    }
    catch (e) {
      console.log(e)
      setError(defaultErrorMessage!);
    }
  }, [clientId, setActiveClient, addressStates, setAddressStates, setError])

  useInit(init);

  const clientGeneralInfo = useMemo(() => {
    const a = activeClient?.address;
    const address = a ? `${a.street_address_1 ?? ""} ${a.street_address_2 ?? ""} ${a.city}, ${a.state} ${a.zip_code}` : "";
    return {address, name: client?.name ?? ""}
  }, [client, activeClient])

  return (
    <Container sx={{ py: 2 }}>
      <ContentBackground>
        <ClientDetailHeader client={clientGeneralInfo} />
        <Box p={3}>
          {isInitialized && <Outlet/>}
        </Box>
      </ContentBackground>
    </Container>
  )
}