import { Typography } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import { Field, useFormikContext } from "formik";
import { Select, TextField } from "formik-mui";
import { Contact, Custodian } from "../../../../api/payloads/customer.payload";
import { useEffect } from "react";
import { AccountFormValues } from "./newClientFormSchema";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "100px",
    },
  },
};

type Props = {
  members: Contact[],
  custodian: Custodian | null,
}

export default function IndividualAccountFields({ members, custodian }: Props) {
  const {values, setFieldValue, setFieldTouched, validateForm} = useFormikContext<AccountFormValues>();
  const {clientAccountMember} = values;
  const isNonQualifiedCustodian = !!custodian && custodian.non_qualified;

  useEffect(() => {
    const currentMember = members?.find(member =>member.id === clientAccountMember);
    let accountName = "";
    
    if (isNonQualifiedCustodian) {
      accountName = currentMember ? `${custodian.name} FBO ${currentMember.first_name} ${currentMember.last_name}` : '';
    }
    else if (currentMember) {
      accountName = `${currentMember.first_name} ${currentMember.last_name}`;
    }

    setFieldValue('accountName', accountName).then(() => validateForm());
  }, [members, clientAccountMember, custodian, isNonQualifiedCustodian, setFieldValue, setFieldTouched, validateForm]) 

  return (
     <>
      <Field
        formControl={{ sx:{width: 'calc(50% - 8px)',minWidth: 112, mb:2, mr:2}}}
        component={Select}
        required
        label="Account Owner"
        MenuProps={MenuProps}
        renderValue={(memberID: number) => {
          const currentMember = members.find((member) => (member.id.toString() === memberID.toString()))
          return <Typography>{currentMember ? `${currentMember.first_name} ${currentMember.last_name}` : ""}</Typography>
        }}
        name="clientAccountMember"
        onClose={() => {}} //NO-OP. Needed to override a formik-mui issue that converts the number to a string
      >
        {members.map((member) => <MenuItem key={member.id} value={member.id}><option value={member.id}>{`${member.last_name}, ${member.first_name}`}</option></MenuItem>)}
      </Field>
      <Field disabled name="accountName" required label="Account Name" component={TextField} sx={{width: 'calc(50% - 8px)'}}/>
      {isNonQualifiedCustodian && (
          <Field
            name="accountNumber"
            required
            label="Account Number"
            component={TextField}
            sx={{width: 'calc(50% - 8px)', mr:2}}
          />
      )}
    </>
  );
}