import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { Form, Formik, FormikHelpers } from "formik";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiClient } from "../../../../api/apiClient";
import { InterestedClient, InvestedClient, Investor, InvestorUpdate, PostInterestListPayload, RegDInterestedClient } from "../../../../api/payloads";
import { Account } from "../../../../api/payloads/customer.payload";
import { LoadingButton } from "../../../../components/Buttons";
import { CornerCloseButton } from "../../../../components/Buttons/CornerCloseButton";
import { ErrorContactEmail } from "../../../../components/ErrorContactEmail/ErrorContactEmail";
import { useAuth, useClients, useMaskedParams, useOpportunities, useUI } from "../../../../hooks";
import { isBdc } from '../../../../utility/misc.util';
import { addInvestorFormSchema, InvestorFormValues } from './add-investor-model';
import { AddInvestorFormFields } from "./AddInvestorFormFields";
import { Client } from '../../../../context/ClientsContext';

type InvestorDialogMode = 'add' | 'edit';

type BaseInvestorDialogProps = Omit<DialogProps, 'onClose'> & {
  mode: InvestorDialogMode;
  handleClose: (result?: boolean, data?: Partial<RegDInterestedClient | InterestedClient>) => void;
  setRefreshDataTrue: () => void;
  investedList: Investor[] | InvestedClient[];
  interestedList: InterestedClient[] | RegDInterestedClient[];
};

export type AddInvestorDialogProps = BaseInvestorDialogProps & {
  mode: 'add';
  preSelectedClient?: Client;
  existingInvestorData?: never;
};

export type EditBdcInvestorDialogProps = BaseInvestorDialogProps & {
  mode: 'edit';
  preSelectedClient: Client;
  existingInvestorData: InterestedClient;
};

export type EditRegDInvestorDialogProps = BaseInvestorDialogProps & {
  mode: 'edit';
  preSelectedClient: Client;
  existingInvestorData: RegDInterestedClient;
};

export type InvestorDialogProps = AddInvestorDialogProps | EditBdcInvestorDialogProps | EditRegDInvestorDialogProps;

export const InvestorDialog: FC<InvestorDialogProps> = (props) => {
  let { maxWidth, fullWidth, handleClose, setRefreshDataTrue, interestedList, investedList, preSelectedClient, existingInvestorData, mode, ...rest } = props;
  const navigate = useNavigate()
  const { user } = useAuth();
  const { investmentId } = useMaskedParams();
  const { opportunitiesMap } = useOpportunities();
  const { clients, custodians } = useClients();
  const { setError } = useUI();

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [loading, setLoading] = useState(false);

  const opportunity = useMemo(() => opportunitiesMap[+investmentId!] ?? {}, [investmentId, opportunitiesMap]);
  maxWidth = maxWidth ?? "md";

  const initialValues = useMemo((): InvestorFormValues => ({
    investmentCompany: opportunity,
    isBdc: isBdc(opportunity.investing_entity_type_id),
    isSubsequentInvestment: false,
    existingEntryListEntry: null,
    client: mode === 'edit' ? preSelectedClient! : null,
    account: null,
    investmentAmount: "",
  }), [opportunity, preSelectedClient, mode]);

  useEffect(() => {
    const fetchAccounts = async () => {
      if (preSelectedClient?.id) {
        try {
          setLoading(true);
          const resp = await apiClient.get("/customers/:p0", { routeParams: [preSelectedClient.id] });
          setAccounts(resp.accounts);
        } catch (error) {
          setError("Error fetching accounts for the initial client");
          setAccounts([]);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchAccounts();
  }, [preSelectedClient, setError]);

  const generateAddPayload = useCallback(
  (values: InvestorFormValues, investedList: Investor[]): PostInterestListPayload => {
    let payload: PostInterestListPayload = {
      accountId: values.account!.id,
      commitment: +values.investmentAmount,
      customerId: +values.client!.id,
    };
  
    if (!values.isBdc && values.isSubsequentInvestment) {
      // Only add parentId if it's a subsequent investment and it's non-BDC
      const priorInvestment = investedList.find(
        (investor) => investor.account_id === values.account?.id
      );
      if (priorInvestment) {
        payload = { ...payload, parentId: priorInvestment.id };
      }
    }
    return payload;
  }, []);
  
  // For Editing Investors (Non-BDC Only)
  const generateUpdatePayload = useCallback(
  (values: InvestorFormValues, investedList: Investor[]): InvestorUpdate => {
    let payload: InvestorUpdate = {
      accountId: values.account!.id,
      subscriptionAmount: +values.investmentAmount,
      fundId: investmentId,
      professionalId: user!.id,
    };
  
    if (values.isSubsequentInvestment) {
      const priorInvestment = investedList.find(
        (investor) => investor.account_id === values.account?.id
      );
      if (priorInvestment) {
        payload = { ...payload, parentId: priorInvestment.id };
      }
    }
    return payload;
  }, [investmentId, user]);

  const addNewInvestor = useCallback(
  async (payload: PostInterestListPayload, isBdc:boolean) => {
      const route = isBdc
      ? "/investors/investment-companies/:p0/transactions"
      : "/investors/investment-companies/:p0/investors";
    await apiClient.post(route, { routeParams: [+investmentId], data: payload });
  }, [investmentId]);

  const promoteBdcProspectToInvestor = useCallback(
    async (payload: PostInterestListPayload, clientData: InterestedClient) => {
      payload.appSourceId = clientData.application_source_id;
      payload.created = clientData.created;
      await addNewInvestor(payload, true);
      await apiClient.delete('/investors/bdc-prospects/:p0', { routeParams: [clientData.id] });
    },
    [addNewInvestor]
  );

  const updateRegDInvestor = useCallback(
    async (payload: InvestorUpdate, clientData: RegDInterestedClient) => {
      await apiClient.patch('/investors/investment-companies/:p0/investors/:p1', {
        routeParams: [investmentId, clientData.id],
        data: payload,
      });
    },
    [investmentId]
  );

  const handleAddNewClient = () => {
    navigate("/clients/new-client", {
      state: {
        investmentId: investmentId,
        tab: isBdc(opportunity.investing_entity_type_id) ? 'bdc-interest-list' : 'interest-list',
      }
    })
  };
  
  const handleSubmit = useCallback(async (values: typeof initialValues, helpers: FormikHelpers<typeof initialValues>) => {
      // Doubt this is needed needed but keeping these just in case
    if (values?.client?.id == null || values?.account?.id == null || investmentId == null) {
      return helpers.setErrors({ "client": "Missing data" })
    }

    if (!user) throw new Error();

    setLoading(true);
    try {
      if (mode === 'add') {
        const addPayload = generateAddPayload(values, investedList as Investor[]);
        await addNewInvestor(addPayload, values.isBdc);
      } else if (mode === 'edit') {
        if (values.isBdc) {
          const clientData = existingInvestorData as InterestedClient;
          const addPayload = generateAddPayload(values, []);
          await promoteBdcProspectToInvestor(addPayload, clientData);
        } else {
          const clientData = existingInvestorData as RegDInterestedClient;
          const editPayload = generateUpdatePayload(values, investedList as Investor[]);
          await updateRegDInvestor(editPayload, clientData);
        }
      }

      setRefreshDataTrue();
      handleClose(true, {
        account_id: values.account.id,
        account_name: values.account.account_name,
        commitment_amount: +values.investmentAmount,
      });
    } catch (e) {
      helpers.setSubmitting(false);
      if (e instanceof AxiosError) {
        setError(
          <div>
            {e.response?.data?.message && <Typography>{e.response?.data?.message}</Typography>}
          </div>
        )
      } else {
        setError(
          <div>
            <Typography variant="h6">Something went wrong:</Typography>
            <Typography variant="body1">
              We encountered a problem while adding this investor to this fund.
              Please contact <ErrorContactEmail /> for assistance.
            </Typography>
          </div>
        )
      }
    } finally {
      setLoading(false);
    }
  }, [
    mode,
    investmentId,
    user,
    investedList,
    handleClose,
    setRefreshDataTrue,
    setError,
    generateAddPayload,
    generateUpdatePayload,
    addNewInvestor,
    promoteBdcProspectToInvestor,
    updateRegDInvestor,
    existingInvestorData,
  ]);


  return (
    <Dialog PaperProps={{ sx: { minWidth: '800px' } }} maxWidth={maxWidth} {...rest}>
      <DialogTitle>
       {mode === 'edit' ? 'Confirm' : 'Add Investor'}
        <CornerCloseButton onClick={() => handleClose(false)}></CornerCloseButton>
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={addInvestorFormSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid, submitCount }) => (
          <Form>
            <DialogContent style={{ paddingTop: '0.5rem' }}>
              <AddInvestorFormFields
                interestedList={interestedList}
                investedList={investedList}
                accounts={accounts}
                setAccounts={setAccounts}
                clients={clients}
                custodiansList={custodians}
                isClientPreSelected={!!preSelectedClient}
              />
            </DialogContent>
            <DialogActions>
              <Button color='inherit' onClick={handleAddNewClient} startIcon={<Box><AddIcon sx={{ fontSize: 14 }} /></Box>} sx={{ py: 0 }}>Add New Client</Button>
              <LoadingButton disabled={!isValid && submitCount > 0} color='info' type='submit' loading={loading}>Save</LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}
