import { Add, Delete } from "@mui/icons-material";
import { Box, Button, FormControlLabel, Grid, IconButton, MenuItem, Radio, Tooltip } from "@mui/material";
import { Field, FieldArray, useFormikContext } from "formik";
import { Select, TextField } from "formik-mui";
import { FC, useEffect, useRef } from "react";
import { apiClient } from "../../../../../api/apiClient";
import { Contact, PhoneNumber } from "../../../../../api/payloads/customer.payload";
import { useClients, useInit } from "../../../../../hooks";
import { defaultCountryData } from "../../../../../utility/misc.util";

type EditMemberFormProps =  { showSelectVisionNumber: boolean};

export const EditMemberFormFields: FC<EditMemberFormProps> = ({ showSelectVisionNumber }) => {
  const { countries, setCountries, phoneTypes, setPhoneTypes} = useClients();
  const { values } = useFormikContext<{
    first_name: string,
    nickname: string,
    middle_name: string,
    last_name: string,
    email: string, 
    phone_numbers: PhoneNumber[]
  }>();
  const lastPhoneRef = useRef<HTMLInputElement>();
  const shouldFocus = useRef(false);

  useInit(async () => {
    if (countries.length && phoneTypes.length) {
      return;
    }
    
    const resp = await apiClient.get("/customers/phone-options");
    setCountries(resp.countries);
    setPhoneTypes(resp.phoneNumberTypes);
  }, {signOutOnError: false});
  
  useEffect(() => {
    // Handle auto focusing new phone number input
    if (shouldFocus.current && lastPhoneRef.current)  {
      lastPhoneRef.current.focus();
      shouldFocus.current = false;
    }
  }, [values.phone_numbers])

  const handleAddPhoneNumber = (push: (obj: any) => void) => {
    shouldFocus.current = true;
    push({ number: '', contact_number_type_id: 1, country_data: defaultCountryData });
  }

  return (
    <>
    <Box>
      <Field
        label="First Name"
        disabled
        component={TextField}
        name="first_name"
        sx={{ mr: 2 }} 
        value={values.first_name}
      />
      <Field
        label="Preferred Name"
        component={TextField}
        name="nickname"
        sx={{ mr: 2 }} 
        value={values.nickname}
      />
      <Field
        label="Middle Name"
        component={TextField}
        disabled
        name="middle_name"
        sx={{ mr: 2 }} 
        value={values.middle_name}
      />
      <Field
        label="Last Name"
        disabled
        component={TextField}
        name="last_name"
        value={values.last_name}
      />
    </Box>
      <Field name="email" id="email" label="Email" component={TextField} fullWidth />
      {(!!phoneTypes.length && !!countries.length) && //FIXME: Find a better way to suppress warning while waiting for MenuOptions to fetch
        <FieldArray name="phone_numbers">
          {({ form, remove, push, replace }) => (
            <>
              {(form.values.phone_numbers as Contact['phone_numbers']).map((phone, index) => (
                <Grid sx={{ mb: 1 }} key={index} container spacing={2}>
                  <Grid item>
                    <Field 
                      label="Phone Number" 
                      component={TextField} 
                      name={`phone_numbers[${index}].number`}
                      inputRef={(index === form.values.phone_numbers.length - 1) ? lastPhoneRef : undefined}
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      formControl={{ sx: { minWidth: '100px' } }}
                      component={Select}
                      label="Type"
                      name={`phone_numbers[${index}].contact_number_type_id`}
                    >
                      {phoneTypes.map(pt => <MenuItem key={pt.id} value={pt.id}>{pt.name}</MenuItem>)}
                    </Field>
                  </Grid>
                  <Grid item>
                    <Field
                      formControl={{ sx: { minWidth: '300px' } }}
                      component={Select}
                      label="Country Code"
                      name={`phone_numbers[${index}].country_data`}
                    >
                      {countries.map(c => <MenuItem key={c.id} value={`${c.dial_code};${c.name}`}>{c.name}</MenuItem>)}
                    </Field>
                  </Grid>
                  { showSelectVisionNumber &&
                    <Grid item display='flex' alignItems='center' style={{ paddingTop: 0 }}>
                      <FormControlLabel
                        label='Vision User'
                        control={
                          <Radio color="primary"
                            checked={!!phone.is_vision_number}
                            onChange={(_, checked) => {
                              if (checked) {
                                for (let i = 0; i < values.phone_numbers.length; i++) {
                                  replace(i, {...values.phone_numbers[i], is_vision_number: i === index})
                                }
                              }
                            }}
                            name="cognitoNumberToggle"
                          />
                        }
                      />
                    </Grid>
                  }
                  <Grid item display='flex' alignItems='center' style={{ paddingTop: 0 }}>
                    <Tooltip title="Delete">
                      <IconButton aria-label='delete' onClick={() => remove(index)} color='error'><Delete /></IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              ))}
              <Button color='info'
                onClick={() => handleAddPhoneNumber(push)}
              >
                <Add fontSize="small"/> Add Phone Number
              </Button>
            </>
          )}
        </FieldArray>
      }
    </>
  )
}