import { Box, MenuItem } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { Select, TextField } from 'formik-mui';
import { FC, useEffect } from 'react';
import { apiClient } from '../../../../../../api/apiClient';
import { useClients } from '../../../../../../hooks';
import { DocumentFileUploadField } from '../../CustomFields/DocumentFileUploadField';
import { InstructionDetailFormValues } from '../newInstructionValidationSchema';

export const BrokerageInstructionFields: FC = () => {
  const {addressStates} = useClients();
  const {custodians, setCustodians} = useClients();
  const {setFieldValue} = useFormikContext<InstructionDetailFormValues>();

  const fetchCustodians = async () => {
    const custodians = await apiClient.get("/customers/custodians");
    setCustodians(custodians);
  };

  useEffect(() => {
    if (custodians.length === 0) {
      fetchCustodians();
    }
  }, 
  // Disabling linter as a quick fix since I just need to stop this from running every time I send back an empty list.
  // May want to update this such that we always fetch a fresh custodian list when first loading the form
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [])

  const handleCustodianSelection = (event: any) => {
    const custodian = custodians.find(custodian => custodian.id === event?.target?.value)
    setFieldValue("brokerageInstructions.custodianId", custodian?.id ?? "")
    setFieldValue("brokerageInstructions.aba", custodian?.distribution_instruction_aba ?? "")
    setFieldValue("brokerageInstructions.bankAccountName", custodian?.name ?? "")
    setFieldValue("brokerageInstructions.accountNumber", custodian?.distribution_instruction_account_number ?? "")
    setFieldValue("brokerageInstructions.financialInstitutionName", custodian?.financial_institution_name ?? "")
    setFieldValue("brokerageInstructions.financialCity", custodian?.financial_institution_city ?? "")
    setFieldValue("brokerageInstructions.financialStateId", custodian?.financial_institution_state_id ?? "")
  }

  return (
    <Box sx={{mt: 4, mb:2}}>
      <Box>
        <Field name="brokerageInstructions.instructionName" required label="Distribution Instruction Friendly Name" component={TextField} sx={{width: '50%', mr: 2, ml:2}}/>
        <Field 
          name="brokerageInstructions.custodianId" 
          label="Custodian" 
          component={Select} 
          formControl={{required: true, sx:{width: '40%', ml:1}}}
          onChange={handleCustodianSelection}
          onClose={() => {}} 
          >
            {custodians.filter(custodian => custodian.use_for_brokerage_instruction).map(custodian => (
              <MenuItem key={custodian.id} value={custodian.id}>{custodian.name}</MenuItem>
            ))}
        </Field>
      </Box>
      <Box>
        <Field  name="brokerageInstructions.bankAccountName" required label="Name on Bank Account" component={TextField} sx={{width: '70%', ml:2}} disabled/>
        <Field  name="brokerageInstructions.aba" required label="Bank's Routing Number" component={TextField} sx={{width: '50%', mr: 2, ml: 2}} disabled/>
        <Field  name="brokerageInstructions.accountNumber" required label="Bank Account Number" component={TextField} sx={{width: '40%', ml:1}} disabled/>
      </Box>
      <Box sx={{display: 'flex'}}>
      <Field  name="brokerageInstructions.financialInstitutionName" required label="Financial Institution Name" sx={{width: '50%', mr: 2, ml:2}} component={TextField} disabled />
        <Field name="brokerageInstructions.financialCity" required label="City" component={TextField} sx={{width: '25%', mr: 1, ml:1}} disabled/>
        <Field 
          name="brokerageInstructions.financialStateId" 
          label="State" 
          component={Select} 
          formControl={{required: true}}
          sx={{ width: '147px',}}
          disabled
          onClose={() => {}}
          >
            {addressStates != null && addressStates.map(state => (
              <MenuItem key={state.id} value={state.id}>{state.name}</MenuItem>
            ))}
        </Field>
      </Box>
        <Box>
          <Field required formControl={{required: true}} name="brokerageInstructions.ffc" label="Reference (For Further Credit)" component={TextField} sx={{width: '50%', mr: 2, ml:2}}/>
          <Field required formControl={{required: true}} name="brokerageInstructions.ffcAccountNumber" label="FFC to Account Number" component={TextField} sx={{width: '40%', ml:1}}/>
          <DocumentFileUploadField
          accept=".pdf"
          label="Verification Document"
          name="brokerageInstructions.verificationDocument"
          sx={{width: '30%', mr: 2, ml: 2, mt:2}}
          />
        </Box>
    </Box>
  );
}