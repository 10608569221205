import * as FullStory from '@fullstory/browser';
import { Box, Grid } from "@mui/material";
import { Auth } from "aws-amplify";
import { FC, useCallback, useState } from "react";
import { Outlet, ScrollRestoration, useNavigate } from "react-router-dom";
import { apiClient } from "../../api/apiClient";
import { useAuth, useClients, useEvents, useGpUpdates, useInit, useInvestments, useOpportunities, useProfessionals, useNotifications } from "../../hooks";
import { NotificationActionType, PROFESSIONAL_ID_HEADER } from "../../utility/constants";
import { isProduction, stockImages } from "../../utility/misc.util";
import { Disclosure } from "../Disclosure/Disclosure";
import { NavBar } from "../NavBar/NavBar";
import { getNotificationLink } from '../../routes/router.util';

export const AppShell: FC = () => {
  const [isInitialized, setInitialized] = useState(false);
  const { user, clearUser } = useAuth();
  const { setProfessional, setProfessionalsList, setAdvisory, setProfessionalTypes} = useProfessionals();
  const { setNotifications } = useNotifications();
  const { setClients, setCustodians } = useClients();
  const { setInvestments } = useInvestments();
  const { setOpportunities } = useOpportunities();
  const { setEvents, setEventTypes } = useEvents();
  const { setGpUpdates } = useGpUpdates();
  const navigate = useNavigate();
  apiClient.setHeader(PROFESSIONAL_ID_HEADER, user?.id?.toString() ?? "");

  const init = useCallback(async () => {
    if (!user?.id) {
      user && clearUser();
      await Auth.signOut();
      return navigate("/login");
    }

    const [investments, gpUpdatesResponse, opportunities, professionalAndAdvisory, eventTypes, clients, notificationsResp, events, custodians] = await Promise.all([
      apiClient.get("/investors/investment-companies/"),
      apiClient.get("/portfolio-companies/gp-updates/:p0", { routeParams: [user.id] }),
      apiClient.get("/investors/investment-companies/fundraising-bdcs/"),
      apiClient.get("/professionals/:p0", { routeParams: [user.id] }),
      apiClient.get("/events/event-types"),
      apiClient.get("/professionals/interested-parties/:p0", { routeParams: [user.id] }),
      apiClient.get("/professionals/:p0/notifications", { routeParams: [user.id] }),
      apiClient.get("/events/by-professional/:p0", { routeParams: [user.id] }),
      apiClient.get("/customers/custodians")
    ]);

    const {professional, advisory} = professionalAndAdvisory;
    const updatedLogoGpUpdates = gpUpdatesResponse.map((n, i) => ({
      ...n,
      logo_url: n.logo_url || `/images/${stockImages[i % stockImages.length]}`
    }));

    const professionalsResp = await apiClient.get("/professionals/advisories/:p0/professionals", { routeParams: [advisory.id] });
    const { professionalsList, professionalTypes } = professionalsResp;
    
    setGpUpdates(updatedLogoGpUpdates);
    setOpportunities(opportunities);
    setProfessional(professional);
    setAdvisory(advisory);
    setEventTypes(eventTypes);
    setClients(clients);
    setInvestments(investments);
    setEvents(events);
    setProfessionalsList(professionalsList);
    setProfessionalTypes(professionalTypes);
    setCustodians(custodians);

    const notifications = notificationsResp.map(notification => {
      const actionType = notification.actionType as NotificationActionType;
      if (!Object.values(NotificationActionType).includes(actionType)) {
        console.warn(`Unhandled notification action type: ${notification.actionType}`);
        return { ...notification, link: undefined };
      }

      const link = getNotificationLink(actionType, notification.linkId);

      return {
        ...notification,
        link,
      };
    });
    setNotifications(notifications);
    
    setInitialized(true);

    if (isProduction) {
      FullStory.identify("", {
        displayName: `${professional.first_name} ${professional.last_name}`,
        email: professional.email,
      });
    }
  }, [user, setAdvisory, setOpportunities, setProfessional, setProfessionalsList, setClients, setInvestments, setEvents, setEventTypes, setGpUpdates, setInitialized, clearUser, navigate, setProfessionalTypes, setNotifications, setCustodians]);

  useInit(init);

  return (
    <Box display='flex' flexDirection='column' minHeight='100vh'>
      <ScrollRestoration/>
      <NavBar/>
      <Grid container sx={{flex: 1}}>
        <Grid item xs={12}>
          { isInitialized && <Outlet/> }
        </Grid>
      </Grid>
      <Box px={1}><Disclosure route={"/settings/terms-and-conditions"}/></Box>
    </Box>
  )
}