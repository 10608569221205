import { Field, useFormikContext } from "formik";
import { Select, TextField } from "formik-mui";
import { useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Custodian, EntityResp, PostCustomerEntityResp } from '../../../../api/payloads/customer.payload';
import { AccountFormValues } from './newClientFormSchema';
import { Box } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 'auto',
    },
  },
};

type Props = {
  entities: EntityResp[] | null;
  defaultEntity: PostCustomerEntityResp | null;
  custodian: Custodian | null;
};

export default function EntityAccountFields({ entities, defaultEntity, custodian }: Props) {
  const { setFieldValue, values, validateForm } = useFormikContext<AccountFormValues>();
  const { entityAccount } = values;
  const isNonQualifiedCustodian = !!custodian && custodian.non_qualified === true;

  useEffect(() => {
    defaultEntity && setFieldValue("entityAccount", defaultEntity?.entityId)
  },[defaultEntity, setFieldValue])

  useEffect(() => {
    const currentEntity = entities?.find(entity => entity.id === entityAccount);
    const accountName = `${isNonQualifiedCustodian ? custodian.name + " FBO " : ""}${currentEntity?.name ?? defaultEntity?.entityName ?? ""}`;
    setFieldValue('accountName', accountName).then(() => validateForm());
  }, [custodian, defaultEntity, entities, entityAccount, isNonQualifiedCustodian, setFieldValue, validateForm,])

  const isEntitiesNull = entities == null;
  const options = isEntitiesNull ? [defaultEntity] : entities;

  const isEntityResp = (entity: EntityResp | PostCustomerEntityResp): entity is EntityResp => {
    return (entity as EntityResp).id !== undefined;
  };

  const menuItems = options?.map((entity, index) => {
    if (!entity) return null;

    let id: number | undefined;
    let name: string;

    if (isEntityResp(entity)) {
      id = entity.id;
      name = entity.name ?? "";
    } else {
      id = entity.entityId;
      name = entity.entityName ?? "";
    }
    return (
      <MenuItem key={index} value={id}>
        {name}
      </MenuItem>
    );
  });

  return (
     <>
      <Field
        formControl={{required: true, sx: { width: 'calc(50% - 8px)', minWidth: 112, mb: 2, mr: 2 }}}
        component={Select}
        label="Select Entity"
        MenuProps={MenuProps}
        name="entityAccount"
        onClose={() => {}}
        value={isEntitiesNull ? defaultEntity?.entityId : undefined}
        disabled={isEntitiesNull}
      >
        {menuItems}
      </Field>
      <Field disabled name="accountName" label="Account Name*" component={TextField} sx={{width: 'calc(50% - 8px)'}}/>
      {isNonQualifiedCustodian && (
        <Box>
          <Field
            name="accountNumber"
            required
            label="Account Number"
            component={TextField}
            sx={{width: 'calc(50% - 8px)'}}
          />
        </Box>
      )}
    </>
  );
}